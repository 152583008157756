import * as React from "react"
import { Frame, FrameProps } from "./presentation/Frame"
import { Vector } from "./presentation/Vector"
import { VectorGroup } from "./presentation/VectorGroup"
import { SVG } from "./presentation/SVG"
import { Text } from "./presentation/Text"
import { DeprecatedComponentContainer } from "./presentation/DeprecatedComponentContainer"
import { PropertyControls } from "./types/PropertyControls"
import { ConstraintProperties, ConstraintValues } from "./types/Constraints"
import { Size } from "./types/Size"
import { Rect } from "./types/Rect"
import { WithFractionOfFreeSpace } from "./traits/FreeSpace"
import { Stack } from "../components/Stack/Stack"
import { isArray } from "../utils/utils"
import { safeWindow } from "../utils/safeWindow"
import { nodeIdFromString } from "./utils/nodeIdFromString"
import { WithOverride } from "../deprecated/WithOverride"
import { isReactDefinition } from "./componentLoader"
import { runtime } from "../utils/runtimeInjection"

/**
 * @internal
 */
export interface PropertyTree {
    componentClass?: string
    name?: string | null
    children?: PropertyTree[]
    props?: any
}

/**
 * @internal
 */
export class CanvasStore {
    canvas: PropertyTree = { children: [] }
    listeners: React.Component[] = []
    ids: string[] = []

    static __shared: CanvasStore | null = null
    static shared(data?: PropertyTree): CanvasStore {
        // the build files (standalone, packages, live-preview) will have data and should not hook into the global shared store
        if (data) {
            const store = new CanvasStore()
            store.setCanvas(data)
            return store
        }

        // vekter and preview are served the live version which has no data and should hook into the same shared store
        if (!CanvasStore.__shared) {
            CanvasStore.__shared = new CanvasStore()
        }
        return CanvasStore.__shared
    }

    updateNode(presentationNode: PropertyTree) {
        const id = presentationNode.props.id
        let children = this.canvas.children
        if (!children) {
            this.canvas.children = children = []
        }

        let found = false
        for (let i = 0; i < children.length; i++) {
            const child = children[i]
            if (child.props.id === id) {
                found = true
                children[i] = presentationNode
                break
            }
        }
        if (!found) {
            children.push(presentationNode)
        }
        this.setCanvas(this.canvas)
    }
    setCanvas(canvas: PropertyTree) {
        if (!canvas.children) return
        this.canvas = canvas
        this.listeners.forEach((l, at) => {
            const id = this.ids[at]
            const data = findNodeFor(canvas, id)
            l.setState({ data })
        })
    }
    registerListener(listener: React.Component, idOrName: string): PropertyTree | null {
        this.listeners.push(listener)
        this.ids.push(idOrName)
        return findNodeFor(this.canvas, idOrName)
    }
    removeListener(listener: React.Component) {
        const at = this.listeners.indexOf(listener)
        if (at === -1) return
        this.listeners.splice(at, 1)
        this.ids.splice(at, 1)
    }
}

/**
 * @internal
 */
const builtInComponents = { Frame, Vector, Stack, VectorGroup, SVG, Text, DeprecatedComponentContainer }

class DesignComponent<P> extends React.Component<P & Partial<FrameProps>, { data: any }> {
    _typeForName(name: any) {
        const builtIn = builtInComponents[name]
        if (builtIn) return builtIn

        const codeComponent = runtime.componentLoader.componentForIdentifier(name)
        if (codeComponent && isReactDefinition(codeComponent)) {
            return codeComponent.class
        }

        return Frame
    }

    _renderData(presentation: any, componentProps: any, topLevelProps?: any) {
        safeWindow["__checkBudget__"]()
        // notice, we don't own the presentation tree, but share it with all instances
        // so we have to be careful not to mutate any aspect of it
        const { componentClass, name } = presentation
        let { props, children } = presentation
        props = { ...props, _constraints: { enabled: false } }
        const type = this._typeForName(componentClass)
        if (!type) return null
        if (topLevelProps) {
            const { style, ...rest } = props
            // Similar to WithOverride,
            // 'style' take presidence over the props, _initialStyle reverses that
            props = { ...rest, ...topLevelProps, _initialStyle: style }
        }

        if (!props.size && props._sizeOfMasterOnCanvas) {
            // We have already copied the props above, so it's safe to modify them here
            if (!props.width) {
                props.width = props._sizeOfMasterOnCanvas.width
            }
            if (!props.height) {
                props.height = props._sizeOfMasterOnCanvas.height
            }
        }

        // eslint-disable-next-line no-prototype-builtins
        if (name && componentProps.hasOwnProperty(name)) {
            if (componentClass === "Text") {
                const text = componentProps[name]
                if (text) {
                    props = { ...props, text: componentProps[name] }
                }
            } else {
                const orig = props.background
                const background = { src: componentProps[name], fit: orig.fit }
                props = { ...props, background }
            }
        }
        const c = children && children.map((child: any) => this._renderData(child, componentProps, undefined))
        children = children ? c : []
        return React.createElement(type, props, children)
    }
    render(): React.ReactNode {
        safeWindow["__checkBudget__"]()

        const data = this.state.data
        if (!data) {
            throw new Error("Unable to connect to canvas data store.")
        }

        return this._renderData(this.state.data, this.props, this.props)
    }
}

function isNode(id: string, presentation: PropertyTree): boolean {
    const { name, props } = presentation
    return (props && props.id === id) || name === id
}

/**
 * @internal
 */
function findNodeFor(presentation: PropertyTree, id: string): PropertyTree | null {
    if (!presentation) return null
    if (isNode(id, presentation)) {
        return presentation
    }
    const { children } = presentation
    if (!children || !isArray(children)) return null
    /* This looks like it could be one loop, but we want this search to be breadth-first.
     * Masters of design components can contain other masters,
     * and we want to find the ones created (and adjusted) by the code-generator,
     * not the ones that are contained by others
     * This fixes https://github.com/framer/company/issues/13070
     */
    for (const child of children) {
        if (isNode(id, child)) {
            return child
        }
    }
    for (const child of children) {
        const result = findNodeFor(child, id)
        if (result) return result
    }
    return null
}

/**
 * @internal
 */
export function createDesignComponent<P>(
    canvasStore: CanvasStore,
    id: string,
    propertyControls: PropertyControls<P>,
    width = 200,
    height = 200
) {
    return class extends DesignComponent<P> {
        static displayName = `DesignComponent(${id})`
        static propertyControls: PropertyControls<P> = propertyControls
        static supportsConstraints = true
        static defaultProps = {
            _sizeOfMasterOnCanvas: {
                width,
                height,
            },
        }
        static rect(props: Partial<ConstraintProperties>): Rect {
            const constraintValues = ConstraintValues.fromProperties(props)
            return ConstraintValues.toRect(constraintValues, props.parentSize || null, null)
        }
        static minSize(props: Partial<ConstraintProperties>, parentSize: any): Size {
            const constraintValues = ConstraintValues.fromProperties(props)
            return ConstraintValues.toMinSize(constraintValues, parentSize || null)
        }
        static size(props: Partial<ConstraintProperties>, parentSize: any, freeSpace: WithFractionOfFreeSpace): Size {
            const constraintValues = ConstraintValues.fromProperties(props)
            return ConstraintValues.toSize(constraintValues, parentSize || null, null, freeSpace)
        }
        constructor(props: P & Partial<ConstraintProperties>, context?: any) {
            // FIXME: behaviorally this code has not changed, but the cast makes it explicit that `null` values
            // from ConstraintProperties can make it into something expecting Partial<FrameLayoutProperties>.
            super(props as P & Partial<FrameProps>, context)
            const data = canvasStore.registerListener(this, id)
            this.state = { data }
        }
        render() {
            const maybeRenderWithProvider = (renderNode: RenderNode) => {
                const nodeId = nodeIdFromString(id)

                // Try and avoid the deprecated DesignComponent (that relies on the
                // presentation tree & CanvasStore) for the live preview and render
                // the node using the new canvas renderer which should be provided
                // via the context.
                //
                // The packages built and published on the store as well as
                // exported packages use local versions of the CanvasStore so we
                // still prefer the presentation tree variation if it exists.
                if (!this.state.data && renderNode) {
                    safeWindow["__checkBudget__"]()
                    const el = renderNode(nodeId)

                    // We need to apply any local props (say applied to via code
                    // component) to the rendered node. We use overrides for this.
                    // NOTE: The WithOverride function is deprecated we should
                    // use the Vekter WithOverrides function when pulling
                    // DesignComponents out of the Library.
                    if (el && React.isValidElement(el) && typeof el.type !== "string") {
                        // Create a clone of the element wrapped in an override component.
                        // Ideally we'd just use React.cloneElement() but renderNode()
                        // has already generated the `style` prop at this point based
                        // on the original props. The WithOverride component
                        // contains the logic to re-compute the style property.
                        return React.createElement(WithOverride(el.type, this.props), el.props)
                    }
                }

                // Otherwise fallback to using the presentation tree in this.state.data.
                return super.render()
            }

            return <RenderNodeContext.Consumer>{maybeRenderWithProvider}</RenderNodeContext.Consumer>
        }
        componentWillUnmount() {
            canvasStore.removeListener(this)
        }
    }
}

/** Given a CanvasNode id return a ReactNode */
type RenderNode = (nodeId: string) => React.ReactNode

const RenderNodeContext = React.createContext<RenderNode | null>(null)

/**
 * @internal
 * The new preview renderer uses the Framer document & build scripts directly
 * to generate the preview rather than an intermediate presentation tree. So
 * we no longer need to use the CanvasStore to hold a presentation tree
 * representation of the design components for use in canvas.tsx. Instead
 * the Preview React app should provide a RenderNode implementation that
 * given a CanvasNode id returns a ReactElement for the design component.
 */
export const RenderNodeProvider = RenderNodeContext.Provider
