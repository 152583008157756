import * as React from "react"
import { Layer, LayerProps } from "./Layer"
import { RenderTarget } from "../types/RenderEnvironment"
import { FrameProps, DeprecatedCoreFrameProps, FrameWithMotion } from "./Frame"
import { isReactChild, isReactElement } from "../../utils/type-guards"
import { safeWindow } from "../../utils/safeWindow"
import { NewConstraintProperties } from "../types/NewConstraints"
import { MotionStyle, AnimateSharedLayout, LayoutGroupContext } from "framer-motion"
import { runtime } from "../../utils/runtimeInjection"
import { AutomaticLayoutIds } from "../../components/AnimateLayout/LayoutIdContext"
import { ComponentContainerContext } from "./ComponentContainerContext"

/**
 * @internal
 */
export interface ComponentContainerProps extends Partial<NewConstraintProperties> {
    style: MotionStyle
    visible: boolean
    componentIdentifier: string
    name?: string
}

/**
 * @internal
 */
export interface ComponentContainerState {
    lastError?: {
        // Used to re-probe component for errors (see render method).
        children: React.ReactNode
        name: string
        message: string
        componentStack: string[]
    }
}

/**
 * @internal
 */
export interface ComponentContainerProperties extends ComponentContainerProps, LayerProps {
    innerRef?: React.RefObject<HTMLDivElement>
}

/**
 * ComponentContainer has been refactored and moved into Vekter. However, we
 * still require a ComponentContainer for symbols that are imported into code
 * via canvas.tsx generation. Since imported symbols are only used as children
 * of another code component, they do not need generated layout ids, and do not
 * need to be measured for auto sizing. This file can be entirely removed when
 * symbols can no longer be imported into code. Likely when code as modules is
 * released.
 *
 * @deprecated
 * @internal
 */
export class DeprecatedComponentContainer extends Layer<
    ComponentContainerProperties & { __layoutId?: string },
    ComponentContainerState
> {
    static supportsConstraints = true
    state: ComponentContainerState = {}

    static defaultComponentContainerProps: ComponentContainerProps = {
        style: {},
        visible: true,
        componentIdentifier: "",
    }

    static readonly defaultProps: ComponentContainerProperties = {
        ...Layer.defaultProps,
        ...DeprecatedComponentContainer.defaultComponentContainerProps,
    }

    static contextType = ComponentContainerContext

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        let stack = info.componentStack.split("\n").filter(line => line.length !== 0)
        let currentIndex = 0
        for (const line of stack) {
            if (line.startsWith(`    in ${this.constructor.name}`)) {
                break
            }
            currentIndex++
        }
        stack = stack.slice(0, currentIndex)
        this.setState({
            lastError: {
                children: this.props.children,
                name: error.name,
                message: error.message,
                componentStack: stack,
            },
        })
    }

    renderErrorPlaceholder(file: string, error: unknown): JSX.Element {
        const { RenderPlaceholder } = runtime
        return (
            <FrameWithMotion {...this.props} background={null}>
                <RenderPlaceholder error={{ error, file }} />
            </FrameWithMotion>
        )
    }

    render() {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()
        let { children } = this.props
        const { componentIdentifier, innerRef } = this.props
        const { lastError: error } = this.state

        // If the file of the component is in has a compile or load error, there will be no children
        // and there will be an error in the componentLoader. If so we render that error.
        // Note, cannot use React.Children.count when children = [null]
        const noChildren = !children || (Array.isArray(children) && children.filter(c => c).length === 0)
        if (noChildren) {
            const errorComponent = runtime.componentLoader.errorForIdentifier(componentIdentifier)
            if (errorComponent) {
                return this.renderErrorPlaceholder(errorComponent.file, errorComponent.error)
            }
        }

        // If an error occurred, componentDidCatch will set error. Additionally, we keep track of the child(ren)
        // reference of this container and only render the error when nothing changed. This means we will
        // re-render the component when something does change, which will either take us out of the error state
        // or update the children reference and keep showing the error. Effectively, this re-probes the component
        // for errors, without throwing an error twice in a row which would make React skip this error boundary
        // and go up the stack.
        if (error && error.children === children) {
            const component = runtime.componentLoader.componentForIdentifier(componentIdentifier)
            const file = component ? component.file : "???"
            return this.renderErrorPlaceholder(file, error.message)
        }

        // This is provided by the time budget logic in runtime
        safeWindow["__checkComponentBudget__"]?.()

        // FIXME: this suppresses warnings about Motion-backed types that aren't supported by the deprecated props.
        let frameProps = this.props as Partial<FrameProps & DeprecatedCoreFrameProps>

        if (RenderTarget.current() !== RenderTarget.canvas) {
            // For Code Overrides, we want the styling properties to be applied to the Frame,
            // and the rest to the actual component
            const {
                left,
                right,
                top,
                bottom,
                center,
                centerX,
                centerY,
                aspectRatio,
                parentSize,
                width,
                height,
                rotation,
                opacity,
                visible,
                _constraints,
                _initialStyle,
                name,
                positionSticky,
                positionStickyTop,
                positionStickyRight,
                positionStickyBottom,
                positionStickyLeft,
                // Remove the children and the componentIdentifier from the props passed into the component
                componentIdentifier: originalComponentIdentifier,
                children: originalChildren,
                style,
                duplicatedFrom,
                widthType,
                heightType,
                ...childProps
            } = frameProps as Partial<FrameProps & DeprecatedCoreFrameProps & ComponentContainerProperties>
            children = React.Children.map(originalChildren, (child: React.ReactElement<typeof childProps>) => {
                if (!isReactChild(child) || !isReactElement(child)) {
                    return child
                }

                // For framer-motion's `layout` to work inside code components,
                // they need to be wrapped in AnimateSharedLayout.
                // Additionally, code components need to avoid generating layout ids for canvas layers.
                if (!isPageOrScroll(originalComponentIdentifier)) {
                    return (
                        <LayoutGroupContext.Provider value={this.props.__layoutId ?? null}>
                            <AnimateSharedLayout>
                                <AutomaticLayoutIds enabled={false}>
                                    {React.cloneElement(child, childProps)}
                                </AutomaticLayoutIds>
                            </AnimateSharedLayout>
                        </LayoutGroupContext.Provider>
                    )
                }

                return React.cloneElement(child, childProps)
            })
            frameProps = {
                style,
                _constraints,
                _initialStyle,
                left,
                right,
                top,
                bottom,
                center,
                centerX,
                centerY,
                aspectRatio,
                parentSize,
                width,
                height,
                rotation,
                visible,
                name,
                duplicatedFrom,
                id: frameProps.id,
                layoutId: this.props.__layoutId,
                widthType,
                heightType,
                positionSticky,
                positionStickyTop,
                positionStickyRight,
                positionStickyBottom,
                positionStickyLeft,
            }
        }

        return (
            /* The background should come before the frameProps. It looks like there never should be a background in frameProps,
             * but published design components can contain an old version of the presentation tree that expects the background
             * that is passed to be rendered here
             * See the stackBackgroundTest.tsx integration test for an example of such a case
             */
            <ComponentContainerContext.Provider value>
                <FrameWithMotion
                    data-framer-component-container
                    background={null}
                    overflow="visible"
                    ref={innerRef}
                    {...frameProps}
                >
                    {children}
                </FrameWithMotion>
            </ComponentContainerContext.Provider>
        )
    }
}

function isPageOrScroll(identifier?: string) {
    if (!identifier) return false
    if (identifier === "framer/Page") return true
    if (identifier === "framer/Scroll") return true
    return false
}
