import type { PageContentDimension, PageAlignment } from "./types"

import * as React from "react"
import { MotionValue, PanInfo } from "framer-motion"
import { FrameWithMotion } from "../../render/presentation/Frame"
import { toJustifyOrAlignment } from "../Stack/Stack"

interface PageContainerProps {
    effect: { [key: string]: MotionValue } | undefined
    children?: React.ReactNode
    dragEnabled: boolean
    direction: "horizontal" | "vertical"
    contentWidth: PageContentDimension | number
    contentHeight: PageContentDimension | number
    alignment?: PageAlignment
    gap: number
    isLastPage: boolean
    contentOffsetRef: React.MutableRefObject<{ x: MotionValue<number>; y: MotionValue<number> }>
    constraintsRef: React.MutableRefObject<{ top: number; left: number; right: number; bottom: number }>
    directionLock: boolean | undefined
    layoutId: string | undefined
    onDragStart: (event: MouseEvent | TouchEvent, info: PanInfo) => void
    onDrag: (event: MouseEvent | TouchEvent, info: PanInfo) => void
    onDragEnd: (event: MouseEvent | TouchEvent, info: PanInfo) => void
}

export const pageContentWrapperType = "PageContentWrapper"

export function PageContainer({
    children,
    effect,
    dragEnabled,
    direction,
    contentHeight,
    contentWidth,
    alignment,
    gap,
    isLastPage,
    contentOffsetRef,
    constraintsRef,
    directionLock,
    onDragStart,
    onDrag,
    onDragEnd,
    layoutId,
}: PageContainerProps) {
    const isHorizontalDirection = direction === "horizontal"
    const dragAxis = isHorizontalDirection ? "x" : "y"
    const hasHorizontalGap = isHorizontalDirection && !isLastPage && gap
    const hasVerticalGap = !isHorizontalDirection && !isLastPage && gap
    const hasAutoWidth = contentWidth !== "stretch" && isHorizontalDirection
    const hasAutoHeight = contentHeight !== "stretch" && !isHorizontalDirection
    const wrapperWidth = hasAutoWidth ? "auto" : "100%"
    const wrapperHeight = hasAutoHeight ? "auto" : "100%"
    const containerWidth = hasHorizontalGap && wrapperWidth === "100%" ? `calc(100% + ${gap}px)` : wrapperWidth
    const containerHeight = hasVerticalGap && wrapperHeight === "100%" ? `calc(100% + ${gap}px)` : wrapperHeight

    return (
        <FrameWithMotion
            position="relative"
            data-framer-component-type="PageContainer"
            width={containerWidth}
            height={containerHeight}
            layoutId={layoutId ? `${layoutId}-container` : undefined}
            backgroundColor="transparent"
            drag={dragEnabled ? dragAxis : false}
            dragDirectionLock={directionLock}
            _dragX={contentOffsetRef.current.x}
            _dragY={contentOffsetRef.current.y}
            dragConstraints={constraintsRef.current}
            onDrag={onDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            preserve3d
            style={{
                paddingRight: hasHorizontalGap ? gap : 0,
                paddingBottom: hasVerticalGap ? gap : 0,
            }}
        >
            <FrameWithMotion
                position="relative"
                data-framer-component-type={pageContentWrapperType}
                width={wrapperWidth}
                height={wrapperHeight}
                preserve3d={false}
                backgroundColor="transparent"
                _layoutResetTransform
                key={effect ? Object.keys(effect).join("") : ""}
                style={{
                    ...effect,
                    display: "flex",
                    flexDirection: isHorizontalDirection ? "row" : "column",
                    alignItems: alignment && toJustifyOrAlignment(alignment),
                }}
            >
                {children}
            </FrameWithMotion>
        </FrameWithMotion>
    )
}
