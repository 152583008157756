import * as React from "react"
import { useContext } from "react"
import { LayoutGroupContext } from "framer-motion"

/**
 * Combine layout groups so that descendent components use a layoutId scoped to
 * this ancestor.
 * @internal
 */
export const LayoutGroup = ({
    id,
    children,
}: React.PropsWithChildren<{
    id: string | null | undefined
}>) => {
    const parentLayoutId = useContext(LayoutGroupContext)
    if (!id) return <>{children}</>

    const combinedLayoutId = parentLayoutId && parentLayoutId !== id ? `${parentLayoutId}-${id}` : id
    return <LayoutGroupContext.Provider value={combinedLayoutId}>{children}</LayoutGroupContext.Provider>
}
