import { animate, MotionValue, Tween, Spring } from "framer-motion"
import { warnOnce } from "@framerjs/shared"

type AnimationOptions = (Tween | Spring) & {
    delay?: number
    type?: "tween" | "spring"
}

/**
 * @deprecated
 * @internal
 */
export function startAnimation<V>(
    _key: string,
    value: MotionValue<V>,
    target: V | V[],
    transition: AnimationOptions = {}
) {
    warnOnce(
        `"startAnimation" is unsupported. Use "animate" instead: https://www.framer.com/api/motion/utilities/#animate`
    )

    return new Promise<void>(resolve => {
        animate(value, target, {
            ...transition,
            onComplete: () => resolve(),
        })
    })
}
