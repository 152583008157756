import { RenderTarget } from "../render/types/RenderEnvironment"
/**
 * Returns a constant value based on whether the caller is mounted in a Framer
 * Canvas environment.
 *
 * @internal
 */
export function useIsOnFramerCanvas() {
    return RenderTarget.current() === RenderTarget.canvas
}
