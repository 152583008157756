import * as React from "react"
import { PropertyControls } from "../render/types/PropertyControls"

/**
 * Extends component with property controls
 *
 * ```typescript
 * export const MyComponent = props => <h1>{props.header}</h1>
 *
 * addPropertyControls(MyComponent, {
 *   header:  { type: ControlType.String, title: "Header" },
 * })
 *
 * ```
 * @public
 */
export function addPropertyControls<Props = any>(
    component: React.ComponentType<Props> | React.ForwardRefExoticComponent<Props>,
    propertyControls: PropertyControls<Props>
) {
    Object.assign(component, { propertyControls })
}

/**
 * Get the property controls for a component
 * @param component - The component to retrieve the property controls for
 * @returns The property controls for the given component
 * @internal
 */
export function getPropertyControls<Props = any>(
    component: React.ComponentType<Props> | React.ForwardRefExoticComponent<Props>
): PropertyControls<Props> | undefined {
    // We need to cast the component here because the function accepts any component type by design
    return (component as any).propertyControls
}
