import * as React from "react"

import { BackgroundProperties, collectBackgroundFromProps } from "../traits/Background"
import { RadiusProperties, collectRadiusFromProps } from "../traits/Radius"
import { FilterProperties } from "../traits/Filters"
import { BackgroundFilterProperties } from "../traits/BackdropFilters"
import { BlendingProperties, collectBlendingFromProps } from "../traits/Blending"
import { OverflowProperties, collectOverflowFromProps } from "../traits/Overflow"
import { WithOpacity, collectOpacityFromProps } from "../traits/Opacity"
import { collectFiltersFromProps } from "../utils/filtersForNode"
import { collectBoxShadowsForProps, collectTextShadowsForProps } from "./shadow"
import { BoxShadowProperties } from "../traits/Shadow"
import { TextColorProperties, collectTextColorFromProps } from "../traits/TextColor"
import { MotionStyle } from "framer-motion"

/** @public */
export type DeprecatedVisualProperties = Partial<
    BackgroundProperties &
        RadiusProperties &
        FilterProperties &
        BackgroundFilterProperties &
        BlendingProperties &
        OverflowProperties &
        BoxShadowProperties &
        WithOpacity &
        TextColorProperties
>

/** @internal */
export function collectVisualStyleFromProps(
    props: DeprecatedVisualProperties,
    style: React.CSSProperties | MotionStyle,
    isTextNode: boolean = false
) {
    collectBackgroundFromProps(props, style)
    collectRadiusFromProps(props, style)
    collectFiltersFromProps(props, style)
    collectBlendingFromProps(props, style)
    collectOverflowFromProps(props, style)
    collectOpacityFromProps(props, style)
    collectTextColorFromProps(props, style)
    if (isTextNode) {
        collectTextShadowsForProps(props, style)
    } else {
        collectBoxShadowsForProps(props, style)
    }
}
