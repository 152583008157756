import * as React from "react"
import { useConstant } from "../components/utils/useConstant"
import { useOnCurrentTargetChange } from "../components/NavigationTargetContext"

interface Modifiers {
    altKey: boolean
    ctrlKey: boolean
    metaKey: boolean
    shiftKey: boolean
}

interface Shortcut extends Modifiers {
    key: string
}

const modifierDefaults: Modifiers = {
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
}

function createShortcutDefinition(shortcut: string): Shortcut | undefined {
    const keys = shortcut.split("+")
    const key = keys.pop()

    if (!key) return undefined

    const modifiers: Partial<Modifiers> = {}

    for (const modifier of keys) {
        modifiers[`${modifier}Key`] = true
    }

    return {
        ...modifierDefaults,
        ...modifiers,
        key,
    }
}

/** @internal */
export function useHotkey(shortcut: string, callback: () => void) {
    const inTarget = React.useRef<boolean>(true)
    const shortcutDefinition = useConstant(() => createShortcutDefinition(shortcut))

    useOnCurrentTargetChange((isCurrentTarget, isOverlayed) => {
        inTarget.current = isCurrentTarget && !isOverlayed
        return () => (inTarget.current = false)
    })

    const eventHandler = React.useCallback(
        (event: KeyboardEvent) => {
            if (!shortcutDefinition) return
            if (!inTarget.current) return
            if (!Object.keys(shortcutDefinition).every(key => shortcutDefinition[key] === event[key])) return

            event.preventDefault()
            callback()
        },
        [shortcutDefinition, callback]
    )

    React.useEffect(() => {
        document.addEventListener("keydown", eventHandler)
        return () => document.removeEventListener("keydown", eventHandler)
    }, [eventHandler])
}
