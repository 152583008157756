import { runtime } from "../../utils/runtimeInjection"
import { RenderTarget } from "../types/RenderEnvironment"
// isAssetReference must be imported as a relative path
// to prevent it from being included in the framer.api.md imports list as a module.
import { isAssetReference } from "../../../../app/assets/src/assetReference"

/**
 * @internal
 */
export function imageUrlForAsset(asset: string, size?: number) {
    if (/^\w+:/.test(asset) && !isAssetReference(asset)) return asset

    if (typeof size !== "number") size = undefined
    else if (size <= 512) size = 512
    else if (size <= 1024) size = 1024
    else if (size <= 2048) size = 2048
    else size = 4096

    const isExport = RenderTarget.current() === RenderTarget.export
    return runtime.assetResolver(asset, { size, isExport }) ?? ""
}
