import { safeWindow } from "../../utils/safeWindow"

interface DebouncedFunction<T extends any[]> {
    (...args: T): void
    cancel: () => void
}

/** @internal */
export function debounce<T extends any[]>(fn: (...args: T) => void, time: number): DebouncedFunction<T> {
    let timeout: number | undefined
    const debounced = (...args: T) => {
        safeWindow.clearTimeout(timeout)
        timeout = safeWindow.setTimeout(fn, time, ...args)
    }

    const cancel = () => {
        safeWindow.clearTimeout(timeout)
    }

    debounced.cancel = cancel
    return debounced
}
