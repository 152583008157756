import type { AssetSize } from "./types/Asset"
import { isString } from "./utils"

// We use a Record because this forces TypeScript to warn us when the type is updated or extended
const assetSizes: Record<AssetSize, true> = {
    auto: true,
    small: true,
    medium: true,
    large: true,
    full: true,
}

export function isAssetSize(value: string | null): value is AssetSize {
    return isString(value) && assetSizes[value] === true
}
