import { LinearGradient, RadialGradient } from "../render/types/Gradient"
import { Color } from "../render/types/Color"
import { BackgroundImage } from "../render/types/BackgroundImage"
import { BackgroundProperties } from "../render/traits/Background"
import { TextColorProperties } from "../render/traits/TextColor"
import { isMotionValue } from "../render/utils/isMotionValue"
import { MotionValue } from "framer-motion"

function convertColorObject(
    prop?: string | Color | LinearGradient | RadialGradient | BackgroundImage | MotionValue<string> | null
) {
    if (typeof prop === "string" || isMotionValue(prop)) {
        return prop
    } else if (LinearGradient.isLinearGradient(prop)) {
        return LinearGradient.toCSS(prop)
    } else if (RadialGradient.isRadialGradient(prop)) {
        return RadialGradient.toCSS(prop)
    } else if (Color.isColorObject(prop)) {
        return Color.toRgbString(prop)
    }
    return prop
}

export function convertColorProps<T extends Partial<TextColorProperties & BackgroundProperties>>(props: T): T {
    if (props.background || props.color) {
        const converted = Object.assign({}, props)

        if (props.background) {
            converted.background = convertColorObject(props.background)
        }
        if (props.color) {
            converted.color = convertColorObject(props.color) as string | Color
        }

        return converted
    }

    return props
}
