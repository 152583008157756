import * as React from "react"
import { NavigationTransitionType, NavigationTransitionDirection } from "../../render/types/NavigationLink"
import { NavigationTransitionSide, NavigationTransitionBackdropColor } from "../../components/NavigationTransitions"
import { NavigationContext } from "../Navigation"
const hoistNonReactStatic = require("hoist-non-react-statics")

/**
 * @internal
 * @deprecated - Will be replaced. Use navigation action instead.
 */
export function WithNavigator<T, BaseProps extends React.ClassAttributes<T>>(
    BaseComponent: React.ComponentType<BaseProps & { onTap?: any }>,
    navigationTransition: string,
    navigationTransitionDirection: NavigationTransitionDirection,
    NavigationTarget: (() => React.ReactNode) | undefined,
    navigationTransitionOptions?: NavigationTransitionBackdropColor
): React.ComponentClass<BaseProps> {
    const InternalWithNavigator = class extends React.Component<BaseProps> {
        render() {
            return (
                <NavigationContext.Consumer>
                    {navigation => {
                        const navigate = () => {
                            if (navigationTransition === "goBack") {
                                navigation.goBack()
                                return
                            }
                            if (!NavigationTarget) return

                            const component = NavigationTarget()
                            const appearsFrom = transitionDirectionToSide(navigationTransitionDirection)

                            switch (navigationTransition) {
                                case NavigationTransitionType.instant:
                                    navigation.instant(component)
                                    break
                                case NavigationTransitionType.fade:
                                    navigation.fade(component)
                                    break
                                case NavigationTransitionType.modal:
                                    navigation.modal(component, navigationTransitionOptions)
                                    break
                                case NavigationTransitionType.push:
                                    navigation.push(component, { appearsFrom })
                                    break
                                case NavigationTransitionType.overlay:
                                    navigation.overlay(component, {
                                        ...navigationTransitionOptions,
                                        appearsFrom,
                                    })
                                    break
                                case NavigationTransitionType.flip:
                                    navigation.flip(component, { appearsFrom })
                                    break
                                case NavigationTransitionType.magicMotion:
                                    navigation.magicMotion(component, {})
                                    break
                            }
                        }
                        // Invoke the component’s normal onTap handler as well as the navigation function.
                        const { onTap, ...props } = this.props as any
                        if (onTap) {
                            props.onTap = (...args: unknown[]) => {
                                onTap.apply(this, args)
                                navigate.apply(this, args)
                            }
                        } else {
                            props.onTap = navigate
                        }
                        return <BaseComponent {...props} />
                    }}
                </NavigationContext.Consumer>
            )
        }
    }

    hoistNonReactStatic(InternalWithNavigator, BaseComponent)
    return InternalWithNavigator
}

// Convert deprecated transitionDirection to transitionSide
function transitionDirectionToSide(direction: NavigationTransitionDirection): NavigationTransitionSide {
    switch (direction) {
        case "left":
            return "right"
        case "right":
            return "left"
        case "up":
            return "bottom"
        case "down":
            return "top"
    }
}
