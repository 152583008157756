import { assert, getLogger } from "./index"

const log = getLogger("task-queue")

/**
 * These queues use a single micro task and a single timer to batch small tasks into one large task.
 * Every queue has an associated delay, which is applied to all of its tasks. And every queue has a
 * priority associeted with it, when the overall runner is configured at a higher priority, lower
 * priority queues won't run any tasks at all.
 */

export interface TaskQueueOptions {
    delay?: number
    priority?: number
    maxBatchSize?: number
}

type WrapTaskRunner = (body: () => void) => void
type Task = () => void

class ScheduledTask {
    isCancelled = false

    constructor(public atTime: number, readonly task: Task) {}

    cancel() {
        this.isCancelled = true
    }
}

class TaskQueue {
    // TODO: ideally, this delay would mean tasks would be scheduled after the delay - cpu busy time
    readonly delay: number = 0
    readonly priority: number = 0
    readonly maxBatchSize: number = 0

    incoming: ScheduledTask[] = []
    scheduled: ScheduledTask[] = []

    constructor(private runner: TaskQueueRunner, readonly name: string, readonly options?: TaskQueueOptions) {
        this.delay = options?.delay ?? 0
        this.priority = options?.priority ?? 0
        this.maxBatchSize = options?.maxBatchSize ?? 0
    }

    add(task: Task): ScheduledTask {
        const scheduled = new ScheduledTask(-1, task)
        this.incoming.push(scheduled)
        this.runner.taskAdded()
        return scheduled
    }

    scheduleNewTasks(now: number) {
        if (this.incoming.length === 0) return

        log.debug("scheduling:", this.name, this.incoming.length)
        const atTime = now + this.delay
        for (const scheduled of this.incoming) {
            scheduled.atTime = atTime
            this.scheduled.push(scheduled)
        }
        this.incoming.length = 0
    }

    millisUntilNextTask(now: number): number {
        if (this.scheduled.length === 0) return Infinity
        return this.scheduled[0].atTime - now
    }

    run(now: number) {
        let count = this.scheduled.length
        if (count === 0) return

        if (this.delay > 0) {
            if (this.scheduled[count - 1].atTime > now) {
                count = this.scheduled.findIndex(task => task.atTime > now)
            }
        }

        if (this.maxBatchSize > 0 && count > this.maxBatchSize) {
            count = this.maxBatchSize
        }

        const toRun = this.scheduled.splice(0, count)
        log.debug("running:", this.name, toRun.length)
        for (let i = 0, il = toRun.length; i < il; i++) {
            const scheduled = toRun[i]
            if (scheduled.isCancelled) continue
            scheduled.task()
        }
    }
}

export class TaskQueueRunner {
    private wrapper: WrapTaskRunner = (body: () => void) => body()
    private queues: TaskQueue[] = []
    private currentPriority: number = 0

    setTaskWrapper(wrapper: WrapTaskRunner): this {
        this.wrapper = wrapper
        return this
    }

    setPriority(priority: number): this {
        if (priority === this.currentPriority) return this

        log.debug("set priority:", this.currentPriority, "->", priority)
        this.currentPriority = priority
        this.taskAdded()
        return this
    }

    getPriority(): number {
        return this.currentPriority
    }

    hasImmediateTasksToRun(): boolean {
        return this.millisUntilNextTask(performance.now()) === 0
    }

    getTaskQueue(name: string, options?: TaskQueueOptions) {
        const existing = this.queues.find(q => q.name === name)
        if (existing) {
            const same =
                existing.options?.delay === options?.delay &&
                existing.options?.priority === options?.priority &&
                existing.options?.maxBatchSize === options?.maxBatchSize
            assert(same, "queue", name, "with different options already exists")
            return existing
        }

        const queue = new TaskQueue(this, name, options)
        this.queues.push(queue)
        this.queues.sort((a, b) => a.priority - b.priority)
        return queue
    }

    microTask = false
    taskAdded() {
        if (this.microTask) return
        this.microTask = true
        queueMicrotask(this.scheduleNewTasks)
    }

    private scheduleNewTasks = () => {
        this.microTask = false
        const now = performance.now()
        this.queues.forEach(queue => {
            queue.scheduleNewTasks(now)
        })
        this.rescheduleRun()
    }

    private millisUntilNextTask(now: number): number {
        let until = Infinity
        this.queues.forEach(queue => {
            if (queue.priority < this.currentPriority) return
            until = Math.min(until, queue.millisUntilNextTask(now))
        })
        return Math.max(0, until)
    }

    private atTime: number = Infinity
    private timer: any

    private rescheduleRun() {
        const now = performance.now()
        const delay = this.millisUntilNextTask(now)
        if (!Number.isFinite(delay)) return

        const atTime = now + delay
        if (atTime > this.atTime) return

        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.atTime = now + delay
        this.timer = setTimeout(this.run, delay)
    }

    /** Actually run tasks */
    private run = () => {
        this.atTime = Infinity
        this.timer = null

        const now = performance.now()
        this.wrapper(() => {
            this.queues.forEach(queue => {
                if (queue.priority < this.currentPriority) return
                queue.run(now)
            })
        })

        this.rescheduleRun()
    }
}
