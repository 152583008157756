import { Transition } from "framer-motion"
import { FrameProps, FrameLayoutProperties } from "../render/presentation/Frame"

/**
 * @public
 */
export type NavigationTransitionSide = "left" | "right" | "top" | "bottom"

/**
 * @public
 */
export type NavigationTransitionPosition = Partial<
    Pick<FrameLayoutProperties, "top" | "right" | "bottom" | "left" | "center">
>

/**
 * @public
 */
export interface NavigationTransitionAnimation {
    /**
     * The animation defaults.
     */
    animation?: Transition
}

/**
 * @public
 */
export interface NavigationTransitionBackdropColor {
    /**
     * Defines the backdrop color when the incoming screen is rendered over the current context. Defaults to the iOS dim color.
     */
    backdropColor?: string
}

/**
 * @public
 */
export interface NavigationTransitionAppearsFrom extends NavigationTransitionAnimation {
    /**
     * Defines which side the target will appear from.
     * @remarks
     *
     * - `"left"`
     * - `"right"`
     * - `"top"`
     * - `"bottom"`
     */
    appearsFrom?: NavigationTransitionSide
}

/**
 * @public
 */
export type FadeTransitionOptions = NavigationTransitionAnimation

/**
 * @public
 */
export interface PushTransitionOptions extends NavigationTransitionAnimation, NavigationTransitionAppearsFrom {}

/**
 * @public
 */
export interface ModalTransitionOptions extends NavigationTransitionAnimation, NavigationTransitionBackdropColor {}

/**
 * @public
 */
export interface OverlayTransitionOptions
    extends NavigationTransitionAnimation,
        NavigationTransitionAppearsFrom,
        NavigationTransitionBackdropColor {}

/**
 * @public
 */
export interface FlipTransitionOptions extends NavigationTransitionAnimation, NavigationTransitionAppearsFrom {}

/**
 * Can be used to define a custom navigation transition.
 * @public
 */
export interface NavigationTransition extends NavigationTransitionAnimation, NavigationTransitionBackdropColor {
    /**
     * Defines the begin state of the incoming screen wrapper.
     */
    enter?: Partial<FrameProps>
    /**
     * Defines the end state of the outgoing screen wrapper.
     */
    exit?: Partial<FrameProps>
    /**
     * Defines the position and size of the incoming screen wrapper. Defaults to top, right, bottom, and left of 0.
     */
    position?: NavigationTransitionPosition
    /**
     * Defines whether the incoming screen should render over the current context, like an overlay or modal. Defaults to false.
     */
    overCurrentContext?: boolean
    /**
     * Defines whether a tap in the background should dismiss the screen presented over the current context. Defaults to true.
     */
    goBackOnTapOutside?: boolean
    /**
     * Defines whether the backface of the incoming and outgoing screens should be visible, necessary for certain 3D transitions. Defaults to true.
     */
    backfaceVisible?: boolean
    /**
     * Defines whether the incoming and outgoing screens should auto animate their children. Defaults to false.
     */
    withMagicMotion?: boolean
}

/**
 * @internal
 */
export namespace TransitionDefaults {
    export const Fade: NavigationTransition = {
        exit: { opacity: 0 },
        enter: { opacity: 0 },
    }

    export const PushLeft: NavigationTransition = {
        exit: { x: "-30%" },
        enter: { x: "100%" },
    }

    export const PushRight: NavigationTransition = {
        exit: { x: "30%" },
        enter: { x: "-100%" },
    }

    export const PushUp: NavigationTransition = {
        exit: { y: "-30%" },
        enter: { y: "100%" },
    }

    export const PushDown: NavigationTransition = {
        exit: { y: "30%" },
        enter: { y: "-100%" },
    }

    export const Instant: NavigationTransition = {
        animation: { type: false },
        enter: { opacity: 0 },
    }

    export const Modal: NavigationTransition = {
        overCurrentContext: true,
        goBackOnTapOutside: true,
        position: { center: true },
        enter: { opacity: 0, scale: 1.2 },
    }

    export const OverlayLeft: NavigationTransition = {
        overCurrentContext: true,
        goBackOnTapOutside: true,
        position: { right: 0, top: 0, bottom: 0 },
        enter: { x: "100%" },
    }

    export const OverlayRight: NavigationTransition = {
        overCurrentContext: true,
        goBackOnTapOutside: true,
        position: { left: 0, top: 0, bottom: 0 },
        enter: { x: "-100%" },
    }

    export const OverlayUp: NavigationTransition = {
        overCurrentContext: true,
        goBackOnTapOutside: true,
        position: { bottom: 0, left: 0, right: 0 },
        enter: { y: "100%" },
    }

    export const OverlayDown: NavigationTransition = {
        overCurrentContext: true,
        goBackOnTapOutside: true,
        position: { top: 0, left: 0, right: 0 },
        enter: { y: "-100%" },
    }

    export const FlipLeft: NavigationTransition = {
        backfaceVisible: false,
        exit: { rotateY: -180 },
        enter: { rotateY: 180 },
    }

    export const FlipRight: NavigationTransition = {
        backfaceVisible: false,
        exit: { rotateY: 180 },
        enter: { rotateY: -180 },
    }

    export const FlipUp: NavigationTransition = {
        backfaceVisible: false,
        exit: { rotateX: 180 },
        enter: { rotateX: -180 },
    }

    export const FlipDown: NavigationTransition = {
        backfaceVisible: false,
        exit: { rotateX: -180 },
        enter: { rotateX: 180 },
    }

    export const MagicMotion: NavigationTransition = {
        withMagicMotion: true,
    }
}

/**
 * @internal
 */
export function pushTransition(options: NavigationTransitionAppearsFrom | undefined): NavigationTransition {
    const side: NavigationTransitionSide = options && options.appearsFrom ? options.appearsFrom : "right"
    switch (side) {
        case "right":
            return TransitionDefaults.PushLeft
        case "left":
            return TransitionDefaults.PushRight
        case "bottom":
            return TransitionDefaults.PushUp
        case "top":
            return TransitionDefaults.PushDown
    }
}

/**
 * @internal
 */
export function overlayTransition(options: NavigationTransitionAppearsFrom | undefined): NavigationTransition {
    const side: NavigationTransitionSide = options && options.appearsFrom ? options.appearsFrom : "bottom"
    switch (side) {
        case "right":
            return TransitionDefaults.OverlayLeft
        case "left":
            return TransitionDefaults.OverlayRight
        case "bottom":
            return TransitionDefaults.OverlayUp
        case "top":
            return TransitionDefaults.OverlayDown
    }
}

/**
 * @internal
 */
export function flipTransition(options: NavigationTransitionAppearsFrom | undefined): NavigationTransition {
    const side: NavigationTransitionSide = options && options.appearsFrom ? options.appearsFrom : "bottom"
    switch (side) {
        case "right":
            return TransitionDefaults.FlipLeft
        case "left":
            return TransitionDefaults.FlipRight
        case "bottom":
            return TransitionDefaults.FlipUp
        case "top":
            return TransitionDefaults.FlipDown
    }
}
