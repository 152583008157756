import { Asset, Typeface, TypefaceSourceNames, ReadonlyTypeface, TypefaceLocator, ReadonlyFont, Font } from "./types"

export const customFontSelectorPrefix = "CUSTOM;"

/**
 * Parses and returns the custom font filename if no properties were provided.
 * With properties provided, the preferred family and sub family are chosen over the base font family.
 * */
function getCustomFontName(fileName: string, properties?: Asset["properties"]) {
    if (!properties) return fileName.substring(0, fileName.lastIndexOf("."))

    const fontFamily =
        properties.font.preferredFamily === "" ? properties.font.fontFamily : properties.font.preferredFamily

    // This variant can be used for custom font grouping
    const variant =
        properties.font.preferredSubFamily === "" ? properties.font.fontSubFamily : properties.font.preferredSubFamily

    return `${fontFamily} ${variant}`
}

/** @internal */
export class CustomFontSource {
    name: TypefaceSourceNames = TypefaceSourceNames.Custom

    typefaces: Typeface[] = []
    byFamily = new Map<string, Typeface>()

    assetsByFamily = new Map<string, Asset>()

    importFonts(assets: readonly Asset[]): Font[] {
        this.typefaces.length = 0
        this.byFamily.clear()
        this.assetsByFamily.clear()

        const fonts: Font[] = []

        assets.forEach(asset => {
            if (!this.isValidCustomFontAsset(asset)) {
                return
            }

            const fontName = getCustomFontName(asset.name, asset.properties)
            const typeface: Typeface = this.createTypeface(fontName)
            const font: Font = {
                typeface,
                selector: `${customFontSelectorPrefix}${fontName}`,
                variant: this.inferVariantName(fontName),
                postscriptName: asset.properties?.font.postscriptName,
                file: asset.url,
            }

            typeface.fonts.push(font)
            typeface.owner = asset.ownerType === "team" ? "team" : "project"

            this.assetsByFamily.set(fontName, asset)
            fonts.push(...typeface.fonts)
        })

        return fonts
    }

    private isValidCustomFontAsset(asset: Asset) {
        return (
            asset.mimeType.startsWith("font/") &&
            asset.properties &&
            "font" in asset.properties &&
            asset.properties?.kind === "font" &&
            "fontFamily" in asset.properties?.font
        )
    }

    inferVariantName(family: string) {
        const possibleValues = [
            "thin",
            "ultra light",
            "extra light",
            "light",
            "normal",
            "medium",
            "semi bold",
            "bold",
            "extra bold",
            "black",
        ]
        const possibleValuesWithItalics = [...possibleValues.map(value => `${value} italic`), ...possibleValues]
        const lowerCaseFamily = family.toLowerCase()
        const tokens = [...lowerCaseFamily.split(" "), ...lowerCaseFamily.split("-"), ...lowerCaseFamily.split("_")]
        const foundToken = possibleValuesWithItalics.find(
            value => tokens.includes(value) || tokens.includes(value.replace(/\s+/g, ""))
        )

        // Return found token with each letter capitalized
        if (foundToken) return foundToken.replace(/(^\w|\s\w)/g, char => char.toUpperCase())

        return "Regular"
    }

    createTypeface(family: string): Typeface {
        const existingTypeface = this.byFamily.get(family)
        if (existingTypeface) return existingTypeface

        const typeface: Typeface = {
            source: this.name,
            family,
            fonts: [],
        }

        this.addTypeface(typeface)
        return typeface
    }

    private addTypeface(typeface: Typeface) {
        this.typefaces.push(typeface)
        this.byFamily.set(typeface.family, typeface)
    }

    public parseSelector(selector: string): TypefaceLocator | null {
        if (!selector.startsWith(customFontSelectorPrefix)) return null

        const tokens = selector.split(customFontSelectorPrefix)
        const locator: TypefaceLocator = { source: "custom", family: tokens[1] }
        return locator
    }

    getFontBySelector(selector: string, createFont = true): ReadonlyFont | null {
        const locator = this.parseSelector(selector)
        if (!locator) return null
        if (!createFont && !this.byFamily.get(locator.family)) return null

        return this.getTypefaceByFamily(locator.family).fonts[0]
    }
    getTypefaceByFamily(family: string): ReadonlyTypeface {
        const foundTypeface = this.byFamily.get(family)
        if (foundTypeface) return foundTypeface

        const typeface: Typeface = {
            source: "custom",
            family,
            fonts: [],
        }
        typeface.fonts.push({
            selector: `${customFontSelectorPrefix}${family}`,
            variant: this.inferVariantName(family),
            typeface,
        })
        return typeface
    }
}
