/**
 * @public
 */
export interface SimpleGradient {
    start: string
    end: string
}

const simpleGradientKeys: (keyof SimpleGradient)[] = ["start", "end"]

export function isSimpleGradient(value: any): value is SimpleGradient {
    return value && simpleGradientKeys.every(key => key in value)
}
