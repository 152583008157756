import { Point } from "./Point"

/**
 * @internal
 */
export namespace PathSegment {
    export type HandleMirroring = "straight" | "symmetric" | "disconnected" | "asymmetric"
    // Note: more additions to this namespace below
}

/**
 * @internal
 */
export class PathSegment {
    // #region withClassDiscriminator
    // NOTE: this implementation carefully copies the implementation of `withClassDiscriminator`
    // from Vekter. If making changes here, make sure to sync them to `withClassDiscriminator` as well.
    readonly __class = "PathSegment" as string
    static displayName = "WithClassDiscriminatorMixin(PathSegment)"
    // #endregion

    readonly x: number = 0 // The anchor point of the segment.
    readonly y: number = 0
    readonly handleMirroring: PathSegment.HandleMirroring = "straight"
    readonly handleOutX: number = 0 // Describes the out tangent of the segment.
    readonly handleOutY: number = 0
    readonly handleInX: number = 0 // Describes the in tangent of the segment.
    readonly handleInY: number = 0
    readonly radius: number = 0

    constructor(value?: Partial<PathSegment>) {
        if (value) {
            Object.assign(this, value)
        }
    }

    merge(value: Partial<PathSegment>): PathSegment {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this, value)
    }
}

// #region withClassDiscriminator
// NOTE: this implementation carefully copies the implementation of `withClassDiscriminator`
// from Vekter. If making changes here, make sure to sync them to `withClassDiscriminator` as well.
;(PathSegment.prototype as any).__class = "PathSegment"
// #endregion

/**
 * @internal
 */
export namespace PathSegment {
    export const point = (pathSegment: PathSegment) => {
        return { x: pathSegment.x, y: pathSegment.y }
    }

    export const handleOut = (pathSegment: PathSegment) => {
        return { x: pathSegment.handleOutX, y: pathSegment.handleOutY }
    }

    export const handleIn = (pathSegment: PathSegment) => {
        return { x: pathSegment.handleInX, y: pathSegment.handleInY }
    }

    export const calculatedHandleOut = (pathSegment: PathSegment): Point => {
        switch (pathSegment.handleMirroring) {
            case "symmetric":
            case "disconnected":
            case "asymmetric":
                return Point.add(point(pathSegment), handleOut(pathSegment))
            default:
                return { x: pathSegment.x, y: pathSegment.y }
        }
    }

    export const calculatedHandleIn = (pathSegment: PathSegment): Point => {
        switch (pathSegment.handleMirroring) {
            case "symmetric":
                return Point.subtract(point(pathSegment), handleOut(pathSegment))
            case "disconnected":
            case "asymmetric":
                return Point.add(point(pathSegment), handleIn(pathSegment))
            default:
                return point(pathSegment)
        }
    }

    export const curveDefault = (points: readonly PathSegment[], index: number): Point => {
        if (points.length > 2) {
            let pointBefore: PathSegment
            let pointAfter: PathSegment

            if (index === 0) {
                pointBefore = points[points.length - 1]
            } else {
                pointBefore = points[index - 1]
            }

            if (index === points.length - 1) {
                pointAfter = points[0]
            } else {
                pointAfter = points[index + 1]
            }

            const delta = Point.subtract(point(pointAfter), point(pointBefore))
            return { x: delta.x / 4, y: delta.y / 4 }
        }

        return { x: 10, y: 10 }
    }
}
