import * as React from "react"
import { createBatcher, LayoutGroupContext, motion, MotionStyle, SharedLayoutContext } from "framer-motion"
import { withGeneratedLayoutId } from "../render/utils/withGeneratedLayoutId"
import { useConstant } from "../components/utils/useConstant"
import { AutomaticLayoutIds } from "../components/AnimateLayout/LayoutIdContext"
import { ComponentContainerContext } from "../render/presentation/ComponentContainerContext"

interface Props {
    layoutId?: string | null
    className?: string
    style?: MotionStyle
}

function Providers({ children, layoutId, ...props }: React.PropsWithChildren<Props>) {
    const context = useConstant(createBatcher)
    const outerLayoutId = useConstant(() => (layoutId ? `${layoutId}-container` : undefined))
    return (
        <motion.div layoutId={outerLayoutId} {...props}>
            <ComponentContainerContext.Provider value>
                <SharedLayoutContext.Provider value={context}>
                    <AutomaticLayoutIds enabled={false}>
                        <LayoutGroupContext.Provider value={layoutId ?? null}>
                            {React.Children.map(children, child => {
                                return React.isValidElement(child) ? React.cloneElement(child, { layoutId }) : child
                            })}
                        </LayoutGroupContext.Provider>
                    </AutomaticLayoutIds>
                </SharedLayoutContext.Provider>
            </ComponentContainerContext.Provider>
        </motion.div>
    )
}

/**
 * @internal
 */
export const Container = withGeneratedLayoutId(Providers)
