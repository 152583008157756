import type { MotionValue } from "framer-motion"

import { useLayoutEffect } from "react"
import { isFiniteNumber } from "../../render/utils/isFiniteNumber"
import { isMotionValue } from "../../render/utils/isMotionValue"

export function useUpdateScrollOffset(
    ref: React.RefObject<HTMLDivElement>,
    side: "scrollTop" | "scrollLeft",
    offset: number | MotionValue<number>,
    cancelEmulatedTouchScrollAnimation?: () => void
) {
    useLayoutEffect(
        () => {
            if (isMotionValue(offset)) {
                const updateScrollLeft = () => {
                    cancelEmulatedTouchScrollAnimation?.()
                    const element = ref.current
                    if (element) element[side] = Math.abs(offset.get())
                }
                updateScrollLeft()
                return offset.onChange(updateScrollLeft)
            } else if (isFiniteNumber(offset)) {
                const element = ref.current
                if (!element) return
                cancelEmulatedTouchScrollAnimation?.()
                element[side] = Math.abs(offset)
            }
        },
        // We only want to update on contentOffset changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [offset]
    )
}
