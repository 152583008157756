import { useEffect, useMemo, useState, createContext, useContext } from "react"
import { Store, SetState, DataHook, ActionMap, BoundActionMap } from "./types"
import { createStore } from "./store"

const defaultId = Symbol("default")

/**
 * @internal
 */
export const DataContext = createContext(defaultId)

/**
 * Creates a hook that shares data between components.
 *
 * By default, all calls to the returned hook will call into the same data store. By
 * passing a `storeId` to the returned hook, separate instances of the store can be created.
 *
 * ```jsx
 * import { createData } from 'framer'
 *
 * const useCount = createData(0)
 *
 * // In a component
 * function MyComponent({ storeId = 'default' }) {
 *   const [count, setCount] = useCount(storeId)
 *
 *   function increment() {
 *     setCount(count + 1)
 *   }
 *
 *   return <Frame onClick={increment}>{count}</Frame>
 * }
 *
 * // In an override
 * function MyOverride({ storeId = 'default' }): Override {
 *   const [count, setCount] = useCount(storeId)
 *
 *   return {
 *     children: count,
 *     onClick: () => setCount(count + 1)
 *   }
 * }
 * ```
 *
 * @param defaultState - The default state ot use
 *
 * @internal
 */
export function createData<State>(defaultState: State): DataHook<State, SetState<State>>
/**
 * By passing an object of named functions as the second argument to `createData`, actions
 * can be created to specify specific ways in which components can update the data.
 *
 * ```jsx
 * const useCount = createData(0, {
 *   add: (state, data: number) => state + data
 * })
 *
 * function MyOverride(): Override {
 *   const [count, actions] = useCount()
 *
 *   return {
 *     children: count,
 *     onClick: () => actions.add(5)
 *   }
 * }
 * ```
 *
 * @param defaultState - The default state to use
 * @param actions - A set of actions that can be performed on the data
 *
 * @internal
 */

export function createData<State, Actions extends ActionMap<State>>(
    defaultState: State,
    actions: Actions
): DataHook<State, BoundActionMap<State, Actions>>
export function createData<State, Actions extends ActionMap<State>>(defaultState: State, actions?: Actions) {
    const stores = new Map<symbol | string, Store<State, Actions>>()

    const useData = (id: string | symbol, initialState?: State) => {
        const contextId = useContext(DataContext)
        id = id || contextId

        const store = useMemo(() => {
            // If no store with this ID exists, create it.
            if (!stores.has(id)) {
                stores.set(id, createStore(initialState || defaultState, actions))
            }

            return stores.get(id) as Store<State, Actions>
        }, [id])

        // Just use a version number to set state rather than replicating the actual data
        // for every subscribed component.
        const [, notifyUpdates] = useState(store.getVersion())

        const storeValueAtHookCallTime = useMemo(() => store.get(), [store])

        // Subscribe to changes from this store.
        useEffect(() => {
            const unsubscribe = store.subscribe(notifyUpdates)

            // if the store value was updated between calling `useData` and≠
            // execution of this `useEffect` callback, e.g. in one of the other subscribers
            // `useEffect(() => { setStoreValue(99) }, [])`
            // make sure we notify the newly created subscriber about that change
            if (storeValueAtHookCallTime !== store.get()) notifyUpdates(store.getVersion())

            return unsubscribe
        }, [store, storeValueAtHookCallTime])

        return [store.get(), store.getActions()]
    }

    return useData
}
