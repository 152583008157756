import { FramerAnimation, DeprecatedAnimationOptions } from "./FramerAnimation"
import { SpringAnimator, SpringOptions, BezierAnimator, BezierOptions, Bezier } from "./Animators"
import { Animatable, AnimatableObject } from "./Animatable"
import { AnimatorClass } from "./Animators/Animator"
import { isAnimatable } from "./Animatable"
import { isMotionValue } from "../render/utils/isMotionValue"
import { DeprecatedAnimationTarget } from "./Animatable/Animatable"
import { deprecationWarning } from "@framerjs/shared"
import { animate as motionAnimate, MotionValue, Spring, Tween } from "framer-motion"

/**
 * Animate an {@link (Animatable:interface)} value to a new value.
 * @remarks
 * Recommended use is to use convenience functions from the `animate` namespace
 * instead of passing an animator. Only use this for low-level animation tweaking.
 *
 * ```jsx
 * const value = Animatable(0)
 * animate(value, 100)
 *
 * const value = Animatable({x: 0, y: 0})
 * animate(value, {x: 100, y: 100})
 * ```
 *
 * @param from - The animatable value or object to start from
 * @param to - Value to animate to
 * @param animator - Animator class to use.
 * @param options - Animation options
 * @returns Instance of {@link FramerAnimation} that can be used to control the animation
 * @public
 * @deprecated Use the {@link AnimationProps.animate} prop on {@link Frame} instead.
 */
export function deprecatedAnimate<Value, Options>(
    from: DeprecatedAnimationTarget<Value>,
    to: Value,
    animator?: AnimatorClass<Value, Options>,
    options?: Partial<Options & DeprecatedAnimationOptions<Value>>
): FramerAnimation<Value, Options> {
    deprecationWarning("animate()", "2.0.0", "the new animation API (https://www.framer.com/api/animation/)")
    const target = from
    let fromValue: Value
    if (isAnimatable(from) || isMotionValue(from)) {
        fromValue = from.get()
    } else {
        fromValue = Animatable.objectToValues(from)
    }
    const animation = new FramerAnimation(target, fromValue, to, animator, options)
    animation.play()
    return animation
}

interface PlaybackControls {
    stop: () => void
}

interface PlaybackLifecycles<V> {
    onUpdate?: (latest: V) => void
    onPlay?: () => void
    onComplete?: () => void
    onRepeat?: () => void
    onStop?: () => void
}

type AnimationOptions<V> = (Tween | Spring) & PlaybackLifecycles<V> & { delay?: number; type?: "tween" | "spring" }

// TODO: When removing support for the deprecated API, simply delete this entire file as `framer-motion` is exported in its entirety.
/**
 * Animate a single value or a `MotionValue`.
 *
 * The first argument is either a `MotionValue` to animate, or an initial animation value.
 *
 * The second is either a value to animate to, or an array of keyframes to animate through.
 *
 * The third argument can be either tween or spring options, and optional lifecycle methods: `onUpdate`, `onPlay`, `onComplete`, `onRepeat` and `onStop`.
 *
 * Returns `PlaybackControls`, currently just a `stop` method.
 *
 * ```javascript
 * const x = useMotionValue(0)
 *
 * useEffect(() => {
 *   const controls = animate(x, 100, {
 *     type: "spring",
 *     stiffness: 2000,
 *     onComplete: v => {}
 *   })
 *
 *   return controls.stop
 * })
 * ```
 *
 * @public
 *
 * @deprecated
 */
export function animate<Value, Options>(
    from: DeprecatedAnimationTarget<Value>,
    to: Value,
    animator?: AnimatorClass<Value, Options>,
    options?: Partial<Options & DeprecatedAnimationOptions<Value>>
): FramerAnimation<Value, Options>
/** @public */
export function animate<V>(from: MotionValue<V> | V, to: V | V[], transition?: AnimationOptions<V>): PlaybackControls
/** @public */
export function animate<V, O>(
    from: DeprecatedAnimationTarget<V> | MotionValue<V> | V,
    to: V | V[],
    animatorOrTransition?: AnimatorClass<V, O> | AnimationOptions<V>,
    options?: Partial<O & DeprecatedAnimationOptions<V>>
) {
    return isAnimatable(from)
        ? deprecatedAnimate(from, to, animatorOrTransition as AnimatorClass<V, O>, options)
        : motionAnimate(from, to, animatorOrTransition as AnimationOptions<V>)
}

export type EaseOptions = Omit<BezierOptions, "curve">

/**
 * @public
 * @deprecated Use the {@link MotionProps.animate} prop on {@link Frame} instead.
 */
export namespace animate {
    /**
     * Animate value with a spring curve
     * @remarks
     * ```jsx
     * const value = Animatable(0)
     * animate.spring(value, 100, {tension: 100, friction: 100})
     *
     * animate.spring(value, 100, {dampingRatio: 0.5, duration: 1})
     * ```
     * @param from - Value to animate
     * @param to - Value to animate to
     * @param options - Options for the spring
     * These can be either `tension`, `friction`, `velocity` and `tolerance` _or_ `dampingRatio`, `duration`, `velocity` and `mass`
     * @returns Instance of {@link FramerAnimation} that can be used to control the animation
     * @deprecated Use {@link MotionProps.animate} on {@link Frame} instead.
     */
    export function spring<Value>(
        from: DeprecatedAnimationTarget<Value>,
        to: Value,
        options?: Partial<SpringOptions & DeprecatedAnimationOptions<Value>>
    ): FramerAnimation<Value, SpringOptions> {
        return animate<Value, SpringOptions>(from, to, SpringAnimator, options)
    }

    /**
     * Animate value with a bezier curve
     * @remarks
     * ```jsx
     * const value = Animatable(0)
     * animate.bezier(value, 100, {duration: 1, curve: Bezier.EaseIn})
     *
     * animate.bezier(value, 100, {duration: 1, curve: [0.3, 0.1, 0.4, 1]})
     * ```
     * @param from - Value to animate
     * @param to - Value to animate to
     * @param options - Options for the bezier curve
     *
     * - `duration` Duration of the animation
     * - `curve` One of the `Bezier` enum values or an array with 4 control points
     *
     * @returns Instance of {@link FramerAnimation} that can be used to control the animation
     * @deprecated Use {@link MotionProps.animate} on {@link Frame} instead.
     */
    export function bezier<Value>(
        from: DeprecatedAnimationTarget<Value>,
        to: Value,
        options?: Partial<BezierOptions & DeprecatedAnimationOptions<Value>>
    ): FramerAnimation<Value, BezierOptions> {
        return animate<Value, BezierOptions>(from, to, BezierAnimator, options)
    }

    /**
     * Animate value with a linear animation
     * @remarks
     * ```jsx
     * const value = Animatable(0)
     * animate.linear(value, 100)
     *
     * animate.linear(value, 100, {duration: 1})
     * ```
     * @param from  - Value to animate
     * @param to - Value to animate to
     * @param options - The options for the animation
     *
     * - `duration` - Duration of the animation
     *
     * @returns Instance of {@link FramerAnimation} that can be used to control the animation
     * @deprecated Use {@link MotionProps.animate} on {@link Frame} instead.
     */
    export function linear<Value>(
        from: DeprecatedAnimationTarget<Value>,
        to: Value,
        options?: Partial<EaseOptions & DeprecatedAnimationOptions<Value>>
    ): FramerAnimation<Value, BezierOptions> {
        return animate.bezier(from, to, { ...options, curve: Bezier.Linear })
    }

    /**
     * Animate value with a ease animation
     * @remarks
     * ```jsx
     * const value = Animatable(0)
     * animate.ease(value, 100)
     *
     * animate.ease(value, 100, {duration: 1})
     * ```
     * @param from  - Value to animate
     * @param to - Value to animate to
     * @param options - The options for the animation
     *
     * - `duration` - Duration of the animation
     *
     * @returns Instance of {@link FramerAnimation} that can be used to control the animation
     * @deprecated Use {@link MotionProps.animate} on {@link Frame} instead.
     */
    export function ease<Value>(
        from: DeprecatedAnimationTarget<Value>,
        to: Value,
        options?: Partial<EaseOptions & DeprecatedAnimationOptions<Value>>
    ): FramerAnimation<Value, BezierOptions> {
        return animate.bezier(from, to, { ...options, curve: Bezier.Ease })
    }

    /**
     * Animate value with a ease in animation
     * @remarks
     * ```jsx
     * const value = Animatable(0)
     * animate.easeIn(value, 100)
     *
     * animate.easeIn(value, 100, {duration: 1})
     * ```
     * @param from  - Value to animate
     * @param to - Value to animate to
     * @param options - The options for the animation
     *
     * - `duration` - Duration of the animation
     *
     * @returns Instance of {@link FramerAnimation} that can be used to control the animation
     * @deprecated Use {@link MotionProps.animate} on {@link Frame} instead.
     */
    export function easeIn<Value>(
        from: DeprecatedAnimationTarget<Value>,
        to: Value,
        options?: Partial<EaseOptions & DeprecatedAnimationOptions<Value>>
    ): FramerAnimation<Value, BezierOptions> {
        return animate.bezier(from, to, { ...options, curve: Bezier.EaseIn })
    }

    /**
     * Animate value with a ease out animation
     * @remarks
     * ```jsx
     * const value = Animatable(0)
     * animate.easeOut(value, 100)
     *
     * animate.easeOUt(value, 100, {duration: 1})
     * ```
     * @param from  - Value to animate
     * @param to - Value to animate to
     * @param options - The options for the animation
     *
     * - `duration` - Duration of the animation
     *
     * @returns Instance of {@link FramerAnimation} that can be used to control the animation
     * @deprecated Use {@link MotionProps.animate} on {@link Frame} instead.
     */
    export function easeOut<Value>(
        from: DeprecatedAnimationTarget<Value>,
        to: Value,
        options?: Partial<EaseOptions & DeprecatedAnimationOptions<Value>>
    ): FramerAnimation<Value, BezierOptions> {
        return animate.bezier(from, to, { ...options, curve: Bezier.EaseOut })
    }

    /**
     * Animate value with a ease in out animation
     * @remarks
     * ```jsx
     * const value = Animatable(0)
     * animate.easeInOut(value, 100)
     *
     * animate.easeInOut(value, 100, {duration: 1})
     * ```
     * @param from  - Value to animate
     * @param to - Value to animate to
     * @param options - The options for the animation
     *
     * - `duration` - Duration of the animation
     *
     * @returns Instance of {@link FramerAnimation} that can be used to control the animation
     * @deprecated Use {@link MotionProps.animate} on {@link Frame} instead.
     */
    export function easeInOut<Value>(
        from: DeprecatedAnimationTarget<Value>,
        to: Value,
        options?: Partial<EaseOptions & DeprecatedAnimationOptions<Value>>
    ): FramerAnimation<Value, BezierOptions> {
        return animate.bezier(from, to, { ...options, curve: Bezier.EaseInOut })
    }
}
