import { LinearGradient, RadialGradient } from "../types/Gradient"
import { ConvertColor } from "../types/Color"
import { gradientColorStops } from "./gradientColorStops"

/** @internal */
interface ColorStop {
    color: string
    alpha: number
    position: number
}

/** @internal */
export interface LinearGradientElementProperties {
    id: string
    angle: number
    stops: ColorStop[]
}

export function elementPropertiesForLinearGradient(
    gradient: LinearGradient,
    id: string
): LinearGradientElementProperties {
    return {
        id: `id${id}g${LinearGradient.hash(gradient)}`,
        angle: gradient.angle - 90,
        stops: gradientColorStops(gradient).map(stop => ({
            color: stop.value,
            alpha: ConvertColor.getAlpha(stop.value) * gradient.alpha,
            position: stop.position,
        })),
    }
}

/** @internal */
export interface RadialGradientElementProperties {
    id: string
    widthFactor: number
    heightFactor: number
    centerAnchorX: number
    centerAnchorY: number
    stops: ColorStop[]
}

export function elementPropertiesForRadialGradient(
    gradient: RadialGradient,
    id: string
): RadialGradientElementProperties {
    return {
        id: `id${id}g${RadialGradient.hash(gradient)}`,
        widthFactor: gradient.widthFactor,
        heightFactor: gradient.heightFactor,
        centerAnchorX: gradient.centerAnchorX,
        centerAnchorY: gradient.centerAnchorY,
        stops: gradientColorStops(gradient).map(stop => ({
            color: stop.value,
            alpha: ConvertColor.getAlpha(stop.value) * gradient.alpha,
            position: stop.position,
        })),
    }
}
