import type { PageAlignment, PageContentDimension, PageEffect } from "./types"

import { ControlType } from "../../render/types/PropertyControls"
import { addPropertyControls } from "../../utils/addPropertyControls"
import { Page } from "./EmulatedPage"

export { Page } from "./EmulatedPage"

namespace ContentDimension {
    export const Auto: PageContentDimension = "auto"
    export const Stretch: PageContentDimension = "stretch"
}

const pageContentDimensionOptions: PageContentDimension[] = [ContentDimension.Auto, ContentDimension.Stretch]
const pageContentDimensionTitles = pageContentDimensionOptions.map(option => {
    switch (option) {
        case ContentDimension.Auto:
            return "Auto"
        case ContentDimension.Stretch:
            return "Stretch"
    }
}) as string[]

const pageEffectOptions: PageEffect[] = ["none", "cube", "coverflow", "wheel", "pile"]
const pageEffectTitles = pageEffectOptions.map(option => {
    switch (option) {
        case "none":
            return "None"
        case "cube":
            return "Cube"
        case "coverflow":
            return "Cover Flow"
        case "wheel":
            return "Wheel"
        case "pile":
            return "Pile"
    }
})

const pageAlignmentOptions: PageAlignment[] = ["start", "center", "end"]
const genericAlignmentTitles = pageAlignmentOptions.map(option => {
    switch (option) {
        case "start":
            return "Start"
        case "center":
            return "Center"
        case "end":
            return "End"
    }
})

addPropertyControls(Page, {
    direction: {
        type: ControlType.Enum,
        options: ["horizontal", "vertical"],
        title: "Direction",
        defaultValue: "horizontal",
        displaySegmentedControl: true,
        optionIcons: ["direction-horizontal", "direction-vertical"],
    },
    directionLock: {
        type: ControlType.Boolean,
        title: "Lock",
        enabledTitle: "1 Axis",
        disabledTitle: "Off",
        defaultValue: true,
    },
    contentWidth: {
        type: ControlType.Enum,
        options: pageContentDimensionOptions,
        optionTitles: pageContentDimensionTitles,
        title: "Width",
        defaultValue: ContentDimension.Stretch,
        displaySegmentedControl: true,
    },
    contentHeight: {
        type: ControlType.Enum,
        options: pageContentDimensionOptions,
        optionTitles: pageContentDimensionTitles,
        title: "Height",
        defaultValue: ContentDimension.Stretch,
        displaySegmentedControl: true,
    },
    alignment: {
        type: ControlType.Enum,
        options: pageAlignmentOptions,
        optionTitles: genericAlignmentTitles,
        title: "Align",
        hidden(props) {
            const { direction, contentWidth, contentHeight } = props
            const isHorizontalDirection = direction === "horizontal"
            const crossDimension = isHorizontalDirection ? contentHeight : contentWidth
            return crossDimension === ContentDimension.Stretch
        },
        defaultValue: "start",
        displaySegmentedControl: true,
        optionIcons: {
            direction: {
                horizontal: ["align-top", "align-middle", "align-bottom"],
                vertical: ["align-left", "align-center", "align-right"],
            },
        },
    },
    gap: {
        type: ControlType.Number,
        min: 0,
        title: "Gap",
        defaultValue: 0,
    },
    padding: {
        type: ControlType.FusedNumber,
        toggleKey: "paddingPerSide",
        toggleTitles: ["Padding", "Padding per side"],
        valueKeys: ["paddingTop", "paddingRight", "paddingBottom", "paddingLeft"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
        title: "Padding",
        defaultValue: 0,
    },
    currentPage: {
        type: ControlType.Number,
        min: 0,
        title: "Current",
        displayStepper: true,
        defaultValue: 0,
    },
    momentum: {
        type: ControlType.Boolean,
        enabledTitle: "On",
        disabledTitle: "Off",
        title: "Momentum",
        defaultValue: false,
    },
    dragEnabled: {
        type: ControlType.Boolean,
        title: "Drag",
        enabledTitle: "On",
        disabledTitle: "Off",
        defaultValue: true,
    },
    wheelEnabled: {
        type: ControlType.Boolean,
        title: "Wheel",
        enabledTitle: "On",
        disabledTitle: "Off",
        defaultValue: false,
    },
    defaultEffect: {
        type: ControlType.Enum,
        options: pageEffectOptions,
        optionTitles: pageEffectTitles,
        title: "Effect",
        defaultValue: "none",
    },
    children: {
        type: ControlType.Array,
        title: "Content",
        control: { type: ControlType.ComponentInstance, title: "Page" },
    },
})
;(Page as any).supportsConstraints = true
