import type { Spring, Tween } from "framer-motion"
import { useNavigation } from "../components/useNavigation"
import * as React from "react"

interface NavigateOptions {
    transition?: "push" | "instant" | "fade" | "modal" | "overlay" | "flip" | "magicMotion"
    appearsFrom?: "left" | "right" | "top" | "bottom"
    backdropColor?: string
    animation?: Spring & Omit<Tween, "type">
}

type ComponentWithPreload<T extends React.ComponentType<any>> = T & {
    preload: () => Promise<void>
}

/**
 * Enhance React.lazy() with a preload function.
 *
 * @internal
 */
export function lazy<T extends React.ComponentType<any>>(
    factory: () => Promise<{ default: T }>
): ComponentWithPreload<T> {
    const LazyComponent = React.lazy(factory)
    let factoryPromise: Promise<void> | undefined
    let LoadedComponent: T | undefined

    const Component = (React.forwardRef(function LazyWithPreload(props, ref) {
        return React.createElement(LoadedComponent ?? LazyComponent, Object.assign(ref ? { ref } : {}, props) as any)
    }) as any) as ComponentWithPreload<T>

    Component.preload = () => {
        if (!factoryPromise) {
            factoryPromise = factory().then(module => {
                LoadedComponent = module.default
            })
        }

        return factoryPromise
    }
    return Component
}

interface Options {
    preload?: ComponentWithPreload<any>[]
}

/**
 * A relative duplicate of useNavigate from runtime to support navigation in
 * combined screens. Additionally supports a preload argument that allows target
 * screens that are lazy imported to be requested before they are navigated to.
 *
 * @internal
 */
export function useNavigate({ preload }: Options = {}) {
    const navigation = useNavigation()

    // On mount, preload future navigation targets.
    React.useEffect(() => {
        if (!navigation) return
        preload?.forEach(component => "preload" in component && component.preload())
    }, [])

    if (!navigation) return () => {}

    return async (target: ComponentWithPreload<any> | "previous", options: NavigateOptions = {}) => {
        if (target === "previous") {
            navigation.goBack()
            return false
        }

        const { appearsFrom, backdropColor, animation } = options

        if (!target) return

        switch (options.transition) {
            case "instant":
                navigation.instant(target)
                break
            case "fade":
                navigation.fade(target, { animation })
                break
            case "push":
                navigation.push(target, { appearsFrom, animation })
                break
            case "modal":
                navigation.modal(target, { backdropColor, animation })
                break
            case "overlay":
                navigation.overlay(target, { appearsFrom, backdropColor, animation })
                break
            case "flip":
                navigation.flip(target, { appearsFrom, animation })
                break
            case "magicMotion":
                navigation.magicMotion(target, { animation })
                break
        }

        // Return false to prevent smart components from proceeding with their event execution.
        return false
    }
}
