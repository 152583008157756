import { ActionControls, Action } from "../render/types/Action"
import { runtime } from "../utils/runtimeInjection"

/**
 * @internal
 */
export interface ActionInfo {
    controls: ActionControls<any>
    title?: string
}

/**
 * Provide a title and controls for an action, used in the Framer UI
 * @param action - a reference to an {@link Action}
 * @param title - the display title of the action
 * @param controls - the action controls
 * @internal
 */
export function addActionControls<Options extends { [key: string]: any }>(
    action: Action<Options>,
    title: string,
    controls: ActionControls<Options>
) {
    runtime.addActionControls(action, title, controls)
}
