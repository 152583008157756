import * as CSS from "./setDocumentStyles"
import { RenderTarget } from "../../render/types/RenderEnvironment"

const componentCSSRules = `

[data-framer-component-type] {
    position: absolute;
}
`

// [data-framer-component-text-autosized] is no longer used, but still supported
// included to maintain backwards compatibility for smart components that were
// generated before it was removed:
// https://github.com/framer/FramerStudio/pull/8270.
const textCSSRules = `
[data-framer-component-type="Text"] {
    cursor: inherit;
}

[data-framer-component-text-autosized] * {
    white-space: pre;
}
`

const stackCSSRules = `
:not([data-framer-generated]) > [data-framer-stack-content-wrapper] > *,
:not([data-framer-generated]) > [data-framer-stack-content-wrapper] > [data-framer-component-type],
:not([data-framer-generated]) > [data-framer-stack-content-wrapper] > [data-framer-stack-gap] > *,
:not([data-framer-generated]) > [data-framer-stack-content-wrapper] > [data-framer-stack-gap] > [data-framer-component-type] {
    position: relative;
}

[data-framer-stack-gap] > * {
    margin-top: calc(var(--stack-gap-y) / 2);
    margin-bottom: calc(var(--stack-gap-y) / 2);
    margin-right: calc(var(--stack-gap-x) / 2);
    margin-left: calc(var(--stack-gap-x) / 2);
}

/* This should take the language direction into account */
[data-framer-stack-direction-reverse="false"]
[data-framer-stack-gap]
> *:first-child,
[data-framer-stack-direction-reverse="true"]
[data-framer-stack-gap]
> *:last-child {
    margin-top: 0;
    margin-left: 0;
}

/* This should take the language direction into account */
[data-framer-stack-direction-reverse="false"]
[data-framer-stack-gap]
> *:last-child,
[data-framer-stack-direction-reverse="true"]
[data-framer-stack-gap]
> *:first-child {
    margin-right: 0;
    margin-bottom: 0;
}
`

const navigationCSSRules = `
NavigationContainer
[data-framer-component-type="NavigationContainer"] > *,
[data-framer-component-type="NavigationContainer"] > [data-framer-component-type] {
    position: relative;
}
`

const scrollCSSRules = `
[data-framer-component-type="Scroll"]::-webkit-scrollbar {
    display: none;
}
[data-framer-component-type="ScrollContentWrapper"] > * {
    position: relative;
}
`

const nativeScrollCSSRules = `
[data-framer-component-type="NativeScroll"] {
    -webkit-overflow-scrolling: touch;
}

[data-framer-component-type="NativeScroll"] > * {
    position: relative;
}

[data-framer-component-type="NativeScroll"].direction-both {
    overflow-x: scroll;
    overflow-y: scroll;
}

[data-framer-component-type="NativeScroll"].direction-vertical {
    overflow-x: hidden;
    overflow-y: scroll;
}

[data-framer-component-type="NativeScroll"].direction-horizontal {
    overflow-x: scroll;
    overflow-y: hidden;
}

[data-framer-component-type="NativeScroll"].direction-vertical > * {
    width: 100% !important;
}

[data-framer-component-type="NativeScroll"].direction-horizontal > * {
    height: 100% !important;
}

[data-framer-component-type="NativeScroll"].scrollbar-hidden::-webkit-scrollbar {
    display: none;
}
`

const pageContentWrapperWrapperCSSRules = `
[data-framer-component-type="PageContentWrapper"] > *,
[data-framer-component-type="PageContentWrapper"] > [data-framer-component-type] {
    position: relative;
}
`

const presenceCSS = `
[data-is-present="false"], [data-is-present="false"] * {
    pointer-events: none !important;
}
`

const cursorCSS = `
[data-framer-cursor="pointer"] {
    cursor: pointer;
}
[data-framer-cursor="grab"] {
    cursor: grab;
}
[data-framer-cursor="grab"]:active {
    cursor: grabbing;
}
`

/**
 * Add propagation-blocking if we're not on the canvas. If we add this while on the canvas,
 * strange behaviour can appear in the Component panel, with the drag event being blocked.
 */
const frameCSSRules = (isPreview: boolean) => {
    return isPreview ? ` [data-framer-component-type="Frame"] * { pointer-events: auto; } ` : ``
}

const textSelectionCSSRules = (isPreview: boolean) => {
    return isPreview
        ? `
        body :not(input):not(textarea):not([contenteditable="true"]) {
            user-select: none;
            -webkit-user-select: none;
        }

        [data-framer-component-type="Text"], [data-framer-component-type="Text"] * {
        pointer-events: none !important;
        user-select: none;
        -webkit-user-select: none;
    }`
        : ``
}

const combineCSSRules = (isPreview: boolean) => `
${componentCSSRules}
${textCSSRules}
${stackCSSRules}
${navigationCSSRules}
${scrollCSSRules}
${nativeScrollCSSRules}
${pageContentWrapperWrapperCSSRules}
${presenceCSS}
${cursorCSS}
${frameCSSRules(isPreview)}
${textSelectionCSSRules(isPreview)}
`

// Only generate preview and non preview styles once
const combinedCSSRules = combineCSSRules(false)
const combinedCSSRulesForPreview = combineCSSRules(true)

export const injectComponentCSSRules = () => {
    const styles = RenderTarget.current() === RenderTarget.preview ? combinedCSSRulesForPreview : combinedCSSRules
    CSS.setDocumentStyles(styles)
}
