import * as React from "react"

/**
 * Variant / Node Id / React Prop / Val
 *
 * @internal
 */
export type VariantProps = Record<string, Record<string, Record<string, unknown>>>

/**
 * Returns combines React props from a hash map based on the active variants.
 *
 * @internal
 */
export function useAddVariantProps(
    baseVariant: string | undefined,
    gestureVariant: string | undefined,
    variantProps: VariantProps
): (id: string) => Record<string, unknown> {
    return React.useCallback(
        (id: string) => {
            if (!variantProps) return {}
            if (!baseVariant) return {}

            // Create an object with all valid props for the target node. Values
            // in higher priority variants override same values in lower
            // priority variants.
            if (gestureVariant) {
                return Object.assign({}, variantProps[baseVariant]?.[id], variantProps[gestureVariant]?.[id])
            }

            return variantProps[baseVariant]?.[id] || {}
        },
        [baseVariant, gestureVariant, variantProps]
    )
}
