import * as React from "react"
import { useContext } from "react"
import { FrameProps } from "../render/presentation/Frame"
import { DataObserverContext } from "./DataObserver"
import { convertColorProps } from "./convertColorProps"
const hoistNonReactStatic = require("hoist-non-react-statics")

/** @public */
export type OverrideObject<T extends object = any> = Partial<T>
/** @public */
export type OverrideFunction<P extends object = any> = (props: P) => Partial<P>
/** @public */
export type Override<T extends object = FrameProps & { [key: string]: any }> = OverrideObject<T> | OverrideFunction<T>

/**
 * @deprecated No longer used by Framer because built into preview. From version ## TODO: add correct version
 * @internal
 */
export function WithOverride<T extends object>(
    Component: React.ComponentType<T>,
    override: Override<T>
): React.ComponentType<T> {
    const useOverride =
        typeof override === "function"
            ? (props: T) => override(convertColorProps(props))
            : () => convertColorProps(override)

    const ComponentWithOverride = function (props: T) {
        useContext(DataObserverContext)
        const overrideProps = useOverride(props)
        const { style, ...rest } = props as any
        return <Component {...rest} {...overrideProps} _initialStyle={style} />
    }
    hoistNonReactStatic(ComponentWithOverride, Component)
    ComponentWithOverride["displayName"] = `WithOverride(${Component.displayName || Component.name})`
    return ComponentWithOverride
}
