import type { Point } from "../../render/types/Point"
import sync from "framesync"
import { inertia } from "popmotion"

enum AnimationPhase {
    None,
    Running,
    Completed,
    Cancelled,
}

function isRunningAnimation(...phases: AnimationPhase[]): boolean {
    let runningAny = false
    let cancelledAny = false
    phases.forEach(phase => {
        runningAny = runningAny || phase === AnimationPhase.Running
        cancelledAny = cancelledAny || phase === AnimationPhase.Cancelled
    })

    return runningAny && !cancelledAny
}

function didFinishAnimations(...phases: AnimationPhase[]): boolean {
    return phases.every(phase => phase === AnimationPhase.None || phase === AnimationPhase.Completed)
}

const timeConstant = 400

export function animatePointWithInertia({
    from,
    velocity,
    onUpdate,
    onComplete,
    onStop,
}: {
    from: Point
    velocity: Point
    onUpdate: (value: Point) => void
    onComplete: () => void
    onStop: () => void
}): { stop: () => void } {
    const latest = from
    let animationPhaseX = AnimationPhase.None
    let animationPhaseY = AnimationPhase.None
    const animations: { stop: () => void }[] = []

    const updateHandler = () => {
        if (isRunningAnimation(animationPhaseX, animationPhaseY)) {
            onUpdate(latest)
        }
    }

    const completionHandler = () => {
        if (didFinishAnimations(animationPhaseX, animationPhaseY)) {
            onComplete()
        }
    }

    if (velocity.x) {
        animationPhaseX = AnimationPhase.Running
        animations.push(
            inertia({
                from: from.x,
                velocity: -velocity.x,
                timeConstant,
                onUpdate: value => {
                    latest.x = value
                    sync.update(updateHandler, false, true)
                },
                onComplete: () => {
                    if (animationPhaseX !== AnimationPhase.Running) {
                        throw Error("animation x should be running when completing")
                    }
                    animationPhaseX = AnimationPhase.Completed
                    completionHandler()
                },
            })
        )
    }

    if (velocity.y) {
        animationPhaseY = AnimationPhase.Running
        animations.push(
            inertia({
                from: from.y,
                velocity: -velocity.y,
                timeConstant,
                onUpdate: value => {
                    latest.y = value
                    sync.update(updateHandler, false, true)
                },
                onComplete: () => {
                    if (animationPhaseY !== AnimationPhase.Running) {
                        throw Error("animation y should be running when completing")
                    }
                    animationPhaseY = AnimationPhase.Completed
                    completionHandler()
                },
            })
        )
    }

    if (!isRunningAnimation(animationPhaseX, animationPhaseY)) {
        completionHandler()
    }

    return {
        stop: () => {
            if (!isRunningAnimation(animationPhaseX, animationPhaseY)) return
            animations.forEach(animation => animation.stop())
            animationPhaseX = animationPhaseX === AnimationPhase.Running ? AnimationPhase.Cancelled : animationPhaseX
            animationPhaseY = animationPhaseY === AnimationPhase.Running ? AnimationPhase.Cancelled : animationPhaseY
            onStop()
        },
    }
}
