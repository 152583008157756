import { safeWindow } from "../../utils/safeWindow"
import * as React from "react"
import { useRef, useEffect } from "react"
import { Animatable } from "../../animation/Animatable"
import { deviceFont } from "../../utils/environment"
import { fontStore, LoadFontResult } from "../fonts/fontStore"
import { collectTextShadowsForProps } from "../style/shadow"
import { FilterProperties } from "../traits/Filters"
import {
    calculateRect,
    NewConstraintProperties,
    ParentSize,
    ParentSizeState,
    useParentSize,
} from "../types/NewConstraints"
import { Rect } from "../types/Rect"
import { RenderTarget } from "../types/RenderEnvironment"
import { Shadow } from "../types/Shadow"
import { collectFiltersFromProps } from "../utils/filtersForNode"
import { injectComponentCSSRules } from "../utils/injectComponentCSSRules"
import { ComponentContainerContext } from "./ComponentContainerContext"
import { Layer, LayerProps } from "./Layer"
import { forceLayerBackingWithCSSProperties } from "../utils/setLayerBacked"
import { isFiniteNumber } from "../utils/isFiniteNumber"
import { useLayoutId } from "../utils/useLayoutId"
import { motion, Transition, Variants } from "framer-motion"
import { transformTemplate } from "../utils/transformTemplate"
import { useMeasureLayout, measureClosestComponentContainer } from "../utils/useMeasureLayout"
import { layoutHintDataPropsForCenter } from "../utils/layoutHintDataPropsForCenter"
import { isString } from "../../utils/utils"
import { DimensionType } from "../types/Constraints"
import type { MotionStyle } from "framer-motion"
import { isShallowEqualArray } from "../utils/isShallowEqualArray"

/**
 * @internal
 */
export type TextAlignment = "left" | "right" | "center" | undefined

/**
 * @internal
 */
export type TextVerticalAlignment = "top" | "center" | "bottom"

/**
 * @internal
 */
export interface TextProps extends NewConstraintProperties, Partial<FilterProperties> {
    rotation: Animatable<number> | number
    visible: boolean
    name?: string
    alignment: TextAlignment
    verticalAlignment: TextVerticalAlignment
    // The single autoSize property will only be passed in when the
    // supportsDomLayout platform check is NOT on, and will ultimately be removed
    // when we no longer have to support Framer desktop.
    autoSize?: boolean
    opacity?: number
    shadows: Readonly<Shadow[]>
    style?: MotionStyle
    text?: string
    font?: string
    parentSize?: ParentSize
}

/**
 * @internal
 */
export interface TextProperties extends TextProps, LayerProps {
    rawHTML?: string
    isEditable?: boolean
    fonts?: string[]
    layoutId?: string | undefined
    className?: string
    /** @internal */
    withExternalLayout?: boolean
    /** @internal for testing */
    environment?(): RenderTarget
    /** @internal */
    innerRef?: React.RefObject<HTMLDivElement>
    transition?: Transition
    variants?: Variants
    /** @internal */
    __fromCanvasComponent?: boolean
}

// Before migrating to functional components we need to get parentSize data from context
/**
 * @internal
 */
export function Text(props: Partial<TextProperties>) {
    const parentSize = useParentSize()
    const layoutId = useLayoutId(props)
    const layoutRef = useRef<HTMLDivElement>(null)

    useMeasureLayout(props, layoutRef)

    const { fonts, __fromCanvasComponent } = props

    // The fonts array is typically regenerated for every change to a text node,
    // so we need to keep track of previous values to avoid calls to the font
    // store when the contents of the array are the same between renders
    const prevFontsRef = useRef<string[] | undefined>([])
    const fontsDidChange = !isShallowEqualArray(prevFontsRef.current ?? [], fonts ?? [])
    prevFontsRef.current = fonts

    useEffect(() => {
        if (!fontsDidChange || !fonts) return

        fontStore.loadWebFontsFromSelectors(fonts).then(results => {
            // After fonts load, layout is likely to shift in auto-sized
            // elements. Since measurements would have typically already been
            // taken at this point, this can lead to selection outlines
            // appearing out of sync with the rendered component. On the canvas
            // we hook into the font loading process and manually trigger a
            // re-render for the node when it completes, which in turn makes
            // sure that all layout measurements take the latest layout shifts
            // into account. In compiled smart components, however, we can't use
            // the same solution. We'll instead check if new fonts have been
            // loaded, and attempt to add a measure request for the closest
            // component container, which in the case of component instances is
            // the only node whose measurements need updating (we don't track
            // measurements for things rendered inside the component itself,
            // which could also be affected by layout shifts).

            // If we're not running on the canvas and from within a smart
            // component, there's no need to measure.
            if (!__fromCanvasComponent || !layoutRef.current || RenderTarget.current() !== RenderTarget.canvas) return

            // We only need to measure if at least one new font has been loaded.
            // Otherwise we assume there was no layout shift.
            const didLoadNewFonts = results.some(
                result => result.status === "fulfilled" && result.value === LoadFontResult.Loaded
            )
            if (!didLoadNewFonts) return

            measureClosestComponentContainer(layoutRef.current)
        })
    }, [fonts])

    return <TextComponent {...props} innerRef={layoutRef} layoutId={layoutId} parentSize={parentSize} />
}

class TextComponent extends Layer<TextProperties, {}> {
    static supportsConstraints = true
    static defaultTextProps: TextProps = {
        opacity: undefined,
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
        _constraints: {
            enabled: true,
            aspectRatio: null,
        },
        rotation: 0,
        visible: true,
        alignment: undefined,
        verticalAlignment: "top",
        shadows: [],
        font: "16px " + deviceFont(),
    }

    static readonly defaultProps: TextProperties = {
        ...Layer.defaultProps,
        ...TextComponent.defaultTextProps,
        isEditable: false,
        environment: RenderTarget.current,
        withExternalLayout: false,
    }

    editorText: string | undefined

    get frame(): Rect | null {
        return calculateRect(this.props, this.props.parentSize || ParentSizeState.Unknown, false)
    }

    getOverrideText() {
        const { _forwardedOverrideId, _forwardedOverrides, id } = this.props
        const forwardedOverrideId = _forwardedOverrideId ?? id
        if (forwardedOverrideId && _forwardedOverrides) {
            const text = _forwardedOverrides[forwardedOverrideId]
            if (isString(text)) {
                return text
            }
        }
    }

    render() {
        // Refactor to use React.useContext()
        return <ComponentContainerContext.Consumer>{this.renderMain}</ComponentContainerContext.Consumer>
    }

    private collectLayout(style: React.CSSProperties, inCodeComponent: boolean) {
        if (this.props.withExternalLayout) return

        const frame = this.frame
        const {
            rotation,
            autoSize,
            positionSticky,
            positionStickyTop,
            positionStickyRight,
            positionStickyBottom,
            positionStickyLeft,
            width: externalWidth,
            height: externalHeight,
            _usesDOMRect,
        } = this.props
        const rotate = Animatable.getNumber(rotation)

        const isDOMLayoutAutoSized = _usesDOMRect && (externalWidth === "auto" || externalHeight === "auto")
        if (frame && !isDOMLayoutAutoSized && RenderTarget.hasRestrictions()) {
            Object.assign(style, {
                transform: `translate(${frame.x}px, ${frame.y}px) rotate(${rotate.toFixed(4)}deg)`,
                // Using “auto” fixes wrapping problems where our size calculation does not work out well when zooming the
                // text (due to rendering differences).
                // TODO: When the `autoSize` prop is removed, it's safe to leave
                // this at `${frame.width}px`, because all auto cases will be
                // handled by DOM layout in the `else` side of the conditional
                width: autoSize ? "auto" : `${frame.width}px`,
                minWidth: `${frame.width}px`,
                height: `${frame.height}px`,
            })
        } else {
            const { left, right, top, bottom } = this.props

            let width: number | string | undefined
            let height: number | string | undefined
            if (autoSize) {
                width = "auto"
                height = "auto"
            } else {
                if (!isFiniteNumber(left) || !isFiniteNumber(right)) {
                    width = externalWidth
                }
                if (!isFiniteNumber(top) || !isFiniteNumber(bottom)) {
                    height = externalHeight
                }
            }

            Object.assign(style, {
                left,
                right,
                top,
                bottom,
                width,
                height,
                rotate,
            })
        }

        if (positionSticky) {
            const onCanvas = RenderTarget.current() === RenderTarget.canvas
            if (!onCanvas || inCodeComponent) {
                style.position = "sticky"
                style.willChange = "transform"
                style.zIndex = 1
                style.top = positionStickyTop
                style.right = positionStickyRight
                style.bottom = positionStickyBottom
                style.left = positionStickyLeft
            }
        }
    }

    /** Used by the ComponentContainerContext */
    private renderMain = (inCodeComponent: boolean) => {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()

        const {
            font,
            visible,
            alignment,
            willChangeTransform,
            opacity,
            id,
            layoutId,
            className,
            transition,
            variants,
            name,
            innerRef,
            __fromCanvasComponent,
            widthType,
            heightType,
            _usesDOMRect,
            autoSize,
            style: styleProp,
        } = this.props
        const frame = this.frame

        if (!visible) {
            return null
        }

        injectComponentCSSRules()

        // We want to hide the Text component underneath the TextEditor when editing.
        const isHidden = this.props.isEditable && this.props.environment!() === RenderTarget.canvas

        const justifyContent = convertVerticalAlignment(this.props.verticalAlignment)

        // Add more styling and support vertical text alignment
        const style: React.CSSProperties = {
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: justifyContent,
            opacity: isHidden ? 0 : opacity,
            flexShrink: 0,
        }

        const dataProps = {
            "data-framer-component-type": "Text",
            "data-framer-name": name,
        }

        // Compatibility for Smart Components generated before
        // https://github.com/framer/FramerStudio/pull/8270.
        if (autoSize) {
            dataProps["data-framer-component-text-autosized"] = "true"
        }

        this.collectLayout(style, inCodeComponent)

        collectFiltersFromProps(this.props, style)
        collectTextShadowsForProps(this.props, style)

        if (style.opacity === 1 || style.opacity === undefined) {
            // Wipe opacity setting if it's the default (1 or undefined)
            delete style.opacity
        }

        if (willChangeTransform) {
            // We're not using Layer.applyWillChange here, because adding willChange:transform causes clipping issues in export
            forceLayerBackingWithCSSProperties(style)
        }

        let rawHTML = this.props.rawHTML
        const text = this.getOverrideText() || this.props.text

        if (isString(text)) {
            if (rawHTML) {
                rawHTML = replaceDraftHTMLWithText(rawHTML, text)
            } else {
                rawHTML = `<p style="font: ${font}">${text}</p>`
            }
        }

        if (this.props.style) {
            Object.assign(style, this.props.style)
        }

        const isDOMLayoutAutoSized =
            _usesDOMRect && (widthType === DimensionType.Auto || heightType === DimensionType.Auto)
        const hasTransformTemplate =
            !frame || !RenderTarget.hasRestrictions() || __fromCanvasComponent || isDOMLayoutAutoSized
        if (hasTransformTemplate) {
            Object.assign(dataProps, layoutHintDataPropsForCenter(this.props.center))
        }

        if (rawHTML) {
            style.textAlign = alignment
            style.lineHeight = "1px"
            style.fontSize = "0px"

            return (
                <motion.div
                    layoutId={layoutId}
                    id={id}
                    {...dataProps}
                    style={{ ...style, ...styleProp }}
                    transformTemplate={hasTransformTemplate ? transformTemplate(this.props.center) : undefined}
                    dangerouslySetInnerHTML={{ __html: rawHTML! }}
                    data-center={this.props.center}
                    className={className}
                    transition={transition}
                    variants={variants}
                    ref={innerRef}
                />
            )
        }
    }
}

// This regular expression will capture the first <span> with attributes that it finds, and
// keep capturing until the last </span> tag that it finds. If we assume that the first and
// last <span> elements are on the same level in the DOM tree, this will work fine.
const textContentRegex = /(<span [^>]+>).*<\/span>/s
function replaceDraftHTMLWithText(rawHTML: string, text: string): string {
    return rawHTML.replace(textContentRegex, (_, span) => span + text + "</span>")
}

function convertVerticalAlignment(verticalAlignment: TextVerticalAlignment): "center" | "flex-start" | "flex-end" {
    switch (verticalAlignment) {
        case "top":
            return "flex-start"
        case "center":
            return "center"
        case "bottom":
            return "flex-end"
    }
}
