import { WithPaths } from "./Path"

/**
 * @internal
 */
export interface WithShape {
    calculatedPaths(): WithPaths
}

const key: keyof WithShape = "calculatedPaths"

/**
 * @internal
 */
export function withShape(target: any): target is WithShape {
    return key in target
}
