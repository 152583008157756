import { MotionValue } from "framer-motion"

interface AddChildProps {
    transformer: (v: any) => any
}

// Lord forgive me for my sins
// This is a hack to support addChild when providing MotionValues created in the latest
// version of framer-motion to older versions, where its expected. This should be removed
// when we can guarentee 3rd party modules are using a single version of framer-motion
// as this will also manifest in less obvious bugs due to multiple contexts etc
;(MotionValue.prototype as any).addChild = function ({ transformer = v => v }: AddChildProps): MotionValue {
    const child = new MotionValue(transformer(this.get()))
    this.onChange((v: any) => child.set(transformer(v)))

    return child
}
