import { gradientColorStops, gradientColorStopsHash } from "../utils/gradientColorStops"
import { SimpleGradient, isSimpleGradient } from "./SimpleGradient"
import { MultiStopGradient, isMultiStopGradient } from "./MultiStopGradient"

/**
 * @public
 */
export interface LinearGradientBase {
    alpha: number
    angle: number
}

/**
 * @public
 */
export type LinearGradient = LinearGradientBase & (SimpleGradient | MultiStopGradient)

const linearGradientKeys: (keyof LinearGradientBase)[] = ["angle", "alpha"]

/**
 * @public
 */
export namespace LinearGradient {
    /**
     * @param value -
     */
    export function isLinearGradient(value: any): value is LinearGradient {
        return (
            value &&
            linearGradientKeys.every(key => key in value) &&
            (isSimpleGradient(value) || isMultiStopGradient(value))
        )
    }

    /** @internal */
    export function hash(linearGradient: LinearGradient): number {
        return linearGradient.angle ^ gradientColorStopsHash(linearGradient, linearGradient.alpha)
    }

    /** @alpha */
    export function toCSS(linearGradient: LinearGradient, overrideAngle?: number) {
        const stops = gradientColorStops(linearGradient, linearGradient.alpha)
        const angle = overrideAngle !== undefined ? overrideAngle : linearGradient.angle
        const cssStops = stops.map(stop => `${stop.value} ${stop.position * 100}%`)
        return `linear-gradient(${angle}deg, ${cssStops.join(", ")})`
    }
}
