import * as React from "react"
import { Layer, LayerProps } from "../Layer"
import {
    ConstraintProperties,
    ConstraintValues,
    isConstraintSupportingChild,
    constraintDefaults,
} from "../../types/Constraints"
import { BorderStyle, Border } from "../../style/BorderComponent"
import { Color } from "../../types/Color"
import { Rect } from "../../types/Rect"
import { Size } from "../../types/Size"
import { RenderTarget, RenderEnvironment } from "../../types/RenderEnvironment"
import { Animatable, AnimatableObject, isAnimatable, Change, Cancel } from "../../../animation/Animatable"
import { ObservableObject } from "../../../data/ObservableObject"
import { BackgroundImage, ImageFit } from "../../types/BackgroundImage"
import { collectVisualStyleFromProps, DeprecatedVisualProperties } from "../../style/collectVisualStyleFromProps"
import { DeprecatedTransformProperties, collectTransformFromProps, transformDefaults } from "../../traits/Transform"
import { MotionStyle } from "framer-motion"
import { safeWindow } from "../../../utils/safeWindow"
import { ProvideParentSize, ConstraintsContext, ParentSizeState } from "../../types/NewConstraints"
import { isFiniteNumber } from "../../utils/isFiniteNumber"
import { BackgroundProperties, DeprecatedBackgroundProperties } from "../../traits/Background"
import { backgroundImageFromProps } from "../../style/backgroundImageFromProps"

/** @internal */
export function cssBackgroundSize(size: ImageFit | undefined) {
    switch (size) {
        case "fit":
            return "contain"
        case "stretch":
            return "100% 100%"
        default:
            return "cover"
    }
}

function collectBackgroundImageFromProps(
    props: Partial<DeprecatedBackgroundProperties & BackgroundProperties>,
    style: React.CSSProperties
): void {
    const image = backgroundImageFromProps(props)

    if (image) {
        style.backgroundImage = `url("${image.src}")`
        style.backgroundSize = cssBackgroundSize(image.fit)
        style.backgroundRepeat = "no-repeat"
        style.backgroundPosition = "center"
    }
}

/** @public */
export interface DeprecatedFrameProperties
    extends ConstraintProperties,
        DeprecatedTransformProperties,
        DeprecatedVisualProperties {
    /**
     * Determines whether the Frame is current visible. Set to `true` by default.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame visible={false} />
     * }
     * ```
     */
    visible: boolean
    /**
     * An optional name for the Frame.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame name="MyFrame" />
     * }
     * ```
     */
    name?: string
    /**
     * Set to `true` to enable backface-visibility.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame backfaceVisibility={true} />
     * }
     * ```
     */
    backfaceVisible?: boolean | Animatable<boolean>
    /**
     * Set the perspective on the z-plane.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame perspective={100px} />
     * }
     * ```
     */
    perspective?: number | Animatable<number>
    /**
     * Set to `true` to preserve 3D.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame preserve3d={true} />
     * }
     * ```
     */
    preserve3d?: boolean | Animatable<boolean>
    /**
     * A border width for the frame. Can be either a single number for all sides or
     * an object describing each side. Set to `0` by default.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame borderWidth={{top: 10, bottom: 10}} />
     * }
     * ```
     */
    borderWidth: number | Partial<{ top: number; bottom: number; left: number; right: number }>
    /**
     * A border color for the Frame. Set to `"#222"` by default.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame borderColor="red" />
     * }
     * ```
     */
    borderColor: string
    /**
     * A border style for the Frame. One of `"solid", "dashed", "dotted"` or `"double"`. Set to `"solid"` by default.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame borderStyle="dotted" />
     * }
     * ```
     */
    borderStyle: BorderStyle
    /**
     * Additional CSSProperties to apply to the frame. Usage is exactly the same as with the
     * standard React style prop.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame style={{color: "red", backgroundColor: "blue"}} />
     * }
     * ```
     */
    style?: React.CSSProperties
    /**
     * An optional className for the Frame.
     * @remarks
     * ```jsx
     * function App() {
     *   return <Frame className="my-frame" />
     * }
     * ```
     */
    className?: string
    /** @internal */
    _overrideForwardingDescription?: { [key: string]: string }
    /** @internal */
    _initialStyle?: Partial<MotionStyle>
}

function toPixelString(value: number | string | undefined): string | undefined {
    return isFiniteNumber(value) ? `${value}px` : value
}

function applyLayoutProp(style: React.CSSProperties, props: DeprecatedCoreFrameProps, key: string) {
    if (props[key] !== undefined) {
        const value = Animatable.get(props[key], undefined)
        style[key] = toPixelString(value)
    }
}

interface DeprecatedFrameState {
    size: AnimatableObject<Size> | Size | null
}

/** @public */
export interface DeprecatedCoreFrameProps extends DeprecatedFrameProperties, LayerProps {}

/**
 * @public
 */
export class DeprecatedFrame extends Layer<DeprecatedCoreFrameProps, DeprecatedFrameState> {
    static supportsConstraints = true
    static defaultFrameSpecificProps: DeprecatedFrameProperties = {
        ...constraintDefaults,
        ...transformDefaults,
        opacity: 1,
        background: Color("rgba(0, 170, 255, 0.3)"),
        visible: true,
        borderWidth: 0,
        borderColor: "#222",
        borderStyle: "solid",
    }

    static readonly defaultProps: DeprecatedCoreFrameProps = {
        ...Layer.defaultProps,
        ...DeprecatedFrame.defaultFrameSpecificProps,
    }

    static rect(props: Partial<ConstraintProperties>): Rect {
        const constraintValues = ConstraintValues.fromProperties(props)
        return ConstraintValues.toRect(constraintValues, props.parentSize || null, null, true)
    }

    get rect() {
        return DeprecatedFrame.rect(this.props)
    }

    element: HTMLDivElement | null = null
    imageDidChange: boolean = false

    state: DeprecatedFrameState = {
        size: null,
    }

    static getDerivedStateFromProps(
        nextProps: Partial<DeprecatedCoreFrameProps>,
        prevState: DeprecatedFrameState
    ): DeprecatedFrameState | null {
        const size = DeprecatedFrame.updatedSize(nextProps, prevState)
        const { target } = RenderEnvironment
        const nextBackgroundImageSrc =
            nextProps.background && BackgroundImage.isImageObject(nextProps.background)
                ? nextProps.background.src
                : null
        if (nextBackgroundImageSrc) {
            return {
                size: size,
            }
        }
        if (prevState.size) {
            if (target === RenderTarget.preview) {
                return null
            }
            if (prevState.size.width === size.width && prevState.size.height === size.height) {
                return null
            }
        }
        return {
            size: size,
        }
    }

    static updatedSize(
        props: Partial<DeprecatedCoreFrameProps>,
        state: DeprecatedFrameState
    ): AnimatableObject<Size> | Size {
        const rect = DeprecatedFrame.rect(props)
        let size = state.size
        const newSize = { width: rect.width, height: rect.height }
        const { target } = RenderEnvironment
        if (!size) {
            if (target === RenderTarget.preview) {
                size = ObservableObject(newSize, true)
            } else {
                size = newSize
            }
        } else {
            if (isAnimatable(size.width) && isAnimatable(size.height)) {
                size.width.set(newSize.width)
                size.height.set(newSize.height)
            } else {
                size = newSize
            }
        }
        return size
    }

    getStyle(): React.CSSProperties {
        const rect = this.rect
        const style: React.CSSProperties = {
            display: "block",
            position: "absolute",
            width: `${rect.width}px`,
            height: `${rect.height}px`,
            pointerEvents: undefined, // TODO: this should be "none" for non-event consuming instances, for performance.
            userSelect: "none",
        }
        let left = Animatable.get<string | number | undefined>(this.props.left, undefined)
        let top = Animatable.get<string | number | undefined>(this.props.top, undefined)

        Object.assign(style, this.props._initialStyle)
        const hasParentSize = this.context.size !== ParentSizeState.Disabled

        const perspective = Animatable.get(this.props.perspective, undefined)
        style.perspective = perspective
        style.WebkitPerspective = perspective

        let backfaceVisibility: "visible" | "hidden" | undefined = undefined
        const backfaceVisible = Animatable.get(this.props.backfaceVisible, undefined)

        if (backfaceVisible === true) {
            backfaceVisibility = "visible"
        } else if (backfaceVisible === false) {
            backfaceVisibility = "hidden"
        }
        style.backfaceVisibility = backfaceVisibility
        style.WebkitBackfaceVisibility = backfaceVisibility

        const preserve3d = Animatable.get(this.props.preserve3d, undefined)
        if (preserve3d === true) {
            style.transformStyle = "preserve-3d"
        } else if (preserve3d === false) {
            style.transformStyle = "flat"
        }

        /**
         * If we don't have ParentSizeState, we can't correctly figure out x/y position based
         * on the parent size and this component's width/height. So we can apply right and bottom
         * directly and let the DOM layout figure out the rest.
         */
        if (!hasParentSize) {
            applyLayoutProp(style, this.props, "right")
            applyLayoutProp(style, this.props, "bottom")

            // If `left` and `top` have been provided here as a percentage from Vekter,
            // these percentages are calculated from the center of the div
            const width = Animatable.get<string | number | undefined>(this.props.width, undefined)
            const stringWidth = toPixelString(width)
            const height = Animatable.get<string | number | undefined>(this.props.height, undefined)
            const stringHeight = toPixelString(height)
            if (typeof left === "string" && (left as string).endsWith("%") && this.props.right === null) {
                left = `calc(${left} - calc(${stringWidth}} / 2))`
                style.width = stringWidth
            }

            if (typeof top === "string" && (top as string).endsWith("%") && this.props.bottom === null) {
                top = `calc(${top} - calc(${stringHeight} / 2))`
                style.height = stringHeight
            }

            // If pinned to both, reset physical dimensions
            if (top !== undefined && style.bottom !== undefined) {
                style.height = undefined
                top = toPixelString(Animatable.get(this.props.top, undefined))
            } else {
                style.height = stringHeight
            }
            if (left !== undefined && style.right !== undefined) {
                style.width = undefined
                left = toPixelString(Animatable.get(this.props.left, undefined))
            } else {
                style.width = stringWidth
            }
        }

        const transformRect: { x: string | number; y: string | number } & Size = { ...rect }
        if (typeof left !== "undefined") {
            transformRect.x = left
        }
        if (typeof top !== "undefined") {
            transformRect.y = top
        }
        collectTransformFromProps(this.props, transformRect, style)
        collectVisualStyleFromProps(this.props, style)
        collectBackgroundImageFromProps(this.props, style)
        Layer.applyWillChange(this.props, style, false)

        // TODO disable style overrides in strict mode
        if (this.props.style) {
            Object.assign(style, this.props.style)
        }

        return style
    }

    private updateStyle = () => {
        if (!this.element) {
            return
        }
        Object.assign(this.element.style, this.getStyle())
    }

    setElement = (element: HTMLDivElement | null) => {
        this.element = element
    }

    // XXX internal state
    propsObserver: AnimatableObject<DeprecatedCoreFrameProps>
    propsObserverCancel?: Cancel

    sizeObserver: AnimatableObject<Size>
    sizeObserverCancel?: Cancel

    componentDidMount() {
        const { target } = RenderEnvironment
        if (target === RenderTarget.preview) {
            this.propsObserver = ObservableObject(this.props, true)
            this.propsObserverCancel = ObservableObject.addObserver(this.propsObserver, this.onPropsChange)
            if (
                this.props.parentSize &&
                isAnimatable(this.props.parentSize.width) &&
                isAnimatable(this.props.parentSize.height)
            ) {
                this.sizeObserver = ObservableObject(this.props.parentSize, true)
                this.sizeObserverCancel = ObservableObject.addObserver(this.sizeObserver, this.onSizeChange)
            }
        }
    }

    componentDidUpdate() {
        const { target } = RenderEnvironment
        this.propsObserverCancel && this.propsObserverCancel()
        this.sizeObserverCancel && this.sizeObserverCancel()
        if (target === RenderTarget.preview) {
            this.propsObserver = ObservableObject(this.props, true)
            this.propsObserverCancel = ObservableObject.addObserver(this.propsObserver, this.onPropsChange)
            if (
                this.props.parentSize &&
                isAnimatable(this.props.parentSize.width) &&
                isAnimatable(this.props.parentSize.height)
            ) {
                this.sizeObserver = ObservableObject(this.props.parentSize, true)
                this.sizeObserverCancel = ObservableObject.addObserver(this.sizeObserver, this.onSizeChange)
            }
        }
    }

    protected onPropsChange = (props: Change<AnimatableObject<DeprecatedCoreFrameProps>>) => {
        const rect = DeprecatedFrame.rect(Animatable.objectToValues(props.value))
        if (this.state.size && isAnimatable(this.state.size.width) && isAnimatable(props.value.width)) {
            this.state.size.width.set(rect.width)
        }
        if (this.state.size && isAnimatable(this.state.size.height) && isAnimatable(props.value.height)) {
            this.state.size.height.set(rect.height)
        }
        this.updateStyle()
    }

    protected onSizeChange = () => {
        this.updateStyle()
    }

    componentWillUnmount() {
        this.propsObserverCancel && this.propsObserverCancel()
        this.propsObserverCancel = undefined
        this.sizeObserverCancel && this.sizeObserverCancel()
        this.sizeObserverCancel = undefined
    }

    render() {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()
        const { visible, id, className } = this.props
        if (!visible) {
            return null
        }

        const style = this.getStyle()
        const rect = this.rect
        const parentSize = { width: rect.width, height: rect.height }

        return (
            <div id={id} style={style} ref={this.setElement} className={className}>
                <ProvideParentSize parentSize={parentSize}>{this.layoutChildren()}</ProvideParentSize>
                <Border {...this.props} />
            </div>
        )
    }

    layoutChildren() {
        let _forwardedOverrides: { [key: string]: any } | undefined = this.props._forwardedOverrides
        const extractions = this.props._overrideForwardingDescription
        if (extractions) {
            let added = false
            _forwardedOverrides = {}
            for (const key in extractions) {
                added = true
                _forwardedOverrides[key] = this.props[extractions[key]]
            }
            if (!added) {
                _forwardedOverrides = undefined
            }
        }

        let children = React.Children.map(this.props.children, (child: React.ReactElement<any>) => {
            if (isConstraintSupportingChild(child)) {
                return React.cloneElement(child, {
                    parentSize: this.state.size,
                    _forwardedOverrides,
                } as any)
            } else if (_forwardedOverrides && child) {
                return React.cloneElement(child as any, { _forwardedOverrides })
            } else {
                return child
            }
        })

        // We wrap raw strings in a default style to display
        if (children && children.length === 1 && typeof children[0] === "string") {
            children = [<Center key="0">{children}</Center>]
        }
        return children
    }
}

DeprecatedFrame.contextType = ConstraintsContext

export const Center: React.SFC<{ style?: React.CSSProperties }> = props => {
    const style = Object.assign(
        {},
        {
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Helvetica",
        },
        props.style || {}
    )

    return <div style={style}>{props.children}</div>
}
