import * as React from "react"
import * as CSS from "../render/utils/setDocumentStyles"

/**
 * Render a React component with css that will be injected into the document's
 * head when the component is initially mounted. The escapedCSS argument can
 * either be a string where each line is a css rule, or an array of css rule
 * strings.
 *
 * @public
 */
export const withCSS = <T extends object>(Component: React.ComponentType<T>, escapedCSS: string | string[]) =>
    React.forwardRef((props: T, ref) => {
        React.useLayoutEffect(() => {
            const css = Array.isArray(escapedCSS) ? escapedCSS : escapedCSS.split("\n")
            css.forEach(rule => rule && CSS.setDocumentStyles(rule))
        }, [])

        return <Component {...props} ref={ref} />
    })
