import { LinearGradient } from "./LinearGradient"
import type { LinearGradientBase } from "./LinearGradient"
import { RadialGradient } from "./RadialGradient"
import type { RadialGradientBase } from "./RadialGradient"

/**
 * @public
 */
type Gradient = LinearGradient | RadialGradient

export { Gradient, LinearGradient, LinearGradientBase, RadialGradient, RadialGradientBase }
