import { assert } from "./assert"

type ErrorWithMetadata = Error & { tags?: Record<string, string>; extras?: Record<string, unknown> }

interface ErrorReport {
    error: ErrorWithMetadata
    tags: Record<string, string>
    extras?: Record<string, unknown>
}

let errorReporter: undefined | ((_: ErrorReport) => void)

/**
 * Sets a handler for reportError. If not set, any attempt to report errors will throw.
 */
export function setErrorReporter(reporter: typeof errorReporter) {
    errorReporter = reporter
}

/**
 * Primitive for error reporting using the externally set handler. Throws if no handler is set.
 */
export function reportError({
    error: maybeError,
    tags,
    extras,
    caller,
}: Omit<ErrorReport, "error"> & {
    error: Error | unknown
    caller: Function
}): Error {
    assert(
        errorReporter,
        "Set up an error callback with setErrorReporter, or configure Sentry with initializeEnvironment"
    )

    const error = reportableError(maybeError, caller)
    errorReporter({ error, tags: { ...error.tags, ...tags }, extras: { ...error.extras, ...extras } })
    return error
}

export function unhandledError(error: unknown): void {
    error = reportableError(error, unhandledError)
    setTimeout(() => {
        throw error
    }, 0)
}

function reportableError(error: unknown, caller: Function): ErrorWithMetadata {
    if (error instanceof Error) {
        return error
    }

    // Handle cases where error is not an error instance so we can correctly
    // capture a stack trace.
    return new UnhandledError(error, caller)
}

class UnhandledError extends Error {
    constructor(error: unknown, caller: Function) {
        const message = error ? JSON.stringify(error) : "No error message provided"
        super(message)
        this.message = message

        // Consistent stack handling across browsers. Chrome allows you to
        // manipulate the callstack with captureStackTrace and filter
        // everything above the `caller` argument.
        // Firefox normally creates the stack on throw, so here we define
        // it in the constructor to match WebKit & Blink.
        if (caller && Error.captureStackTrace) {
            Error.captureStackTrace(this, caller)
        } else {
            // If no start stack function was provided we just use the original stack property
            try {
                throw new Error()
            } catch (e) {
                this.stack = e.stack
            }
        }
    }
}
