import { useContext } from "react"
import { NavigationContext, NavigationInterface } from "./Navigation"

/**
 * @returns NavigationInterface {@link NavigationInterface}
 * @public
 */
export function useNavigation(): NavigationInterface {
    return useContext(NavigationContext)
}
