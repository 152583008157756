import isPropValid from "@emotion/is-prop-valid"
import { isValidMotionProp, motion, MotionStyle, MotionValue, resolveMotionValue } from "framer-motion"
import * as React from "react"
import { forwardRef, RefObject, useContext, useRef } from "react"
import { safeWindow } from "../../../utils/safeWindow"
import { Border } from "../../style/BorderComponent"
import { backgroundImageFromProps } from "../../style/backgroundImageFromProps"
import { BackgroundProperties } from "../../traits/Background"
import {
    ConstraintConfiguration,
    constraintsEnabled,
    NewConstraintProperties,
    ParentSize,
    ParentSizeState,
    useConstraints,
    useProvideParentSize,
} from "../../types/NewConstraints"
import { Rect } from "../../types/Rect"
import { injectComponentCSSRules } from "../../utils/injectComponentCSSRules"
import { processOverrideForwarding } from "../../utils/processOverrideForwarding"
import { transformValues } from "../../utils/transformCustomValues"
import { Layer, LayerProps } from "../Layer"
import { getStyleForFrameProps, hasLeftAndRight, hasTopAndBottom } from "./getStyleForFrameProps"
import {
    BaseFrameProps,
    CSSTransformProperties,
    FrameLayoutProperties,
    MotionDivProps,
    VisualProperties,
} from "./types"
import { useLayoutId } from "../../utils/useLayoutId"
import { transformTemplate } from "../../utils/transformTemplate"
import { useMeasureLayout } from "../../utils/useMeasureLayout"
import { layoutHintDataPropsForCenter } from "../../utils/layoutHintDataPropsForCenter"
import { DimensionType } from "../../types/Constraints"
import { ComponentContainerContext } from "../ComponentContainerContext"
import { BackgroundImageComponent } from "../../style/BackgroundImageComponent"
import { nodeIdFromString } from "../../utils/nodeIdFromString"
import { RenderTarget } from "../../types/RenderEnvironment"

function hasEvents(props: Partial<FrameProps>) {
    for (const key in props) {
        if (
            key === "drag" ||
            key.startsWith("while") ||
            (typeof props[key] === "function" && key.startsWith("on") && !key.includes("Animation"))
        ) {
            return true
        }
    }

    return false
}
const pointerEvents = [
    "onAuxClick",
    "onClick",
    "onDoubleClick",
    "onMouse",
    "onMouseDown",
    "onMouseUp",
    "onTapDown",
    "onTap",
    "onTapUp",
    "onPointer",
    "onPointerDown",
    "onPointerUp",
    "onTouch",
    "onTouchDown",
    "onTouchUp",
]
const pointerEventsSet = new Set([
    ...pointerEvents,
    ...pointerEvents.map(event => `${event}Capture`), // Add capture event variants
])

function getCursorFromEvents(props: Partial<FrameProps>) {
    if (props.drag) {
        return "grab"
    }

    for (const key in props) {
        if (pointerEventsSet.has(key)) {
            return "pointer"
        }
    }

    return undefined
}

export function unwrapFrameProps(
    frameProps: Partial<FrameLayoutProperties & ConstraintConfiguration>
): Partial<NewConstraintProperties> {
    const {
        left,
        top,
        bottom,
        right,
        width,
        height,
        minWidth,
        minHeight,
        center,
        _constraints,
        size,
        widthType,
        heightType,
    } = frameProps
    const constraintProps: Partial<NewConstraintProperties> = {
        top: resolveMotionValue(top),
        left: resolveMotionValue(left),
        bottom: resolveMotionValue(bottom),
        right: resolveMotionValue(right),
        width: resolveMotionValue(width),
        height: resolveMotionValue(height),
        minWidth: resolveMotionValue(minWidth),
        minHeight: resolveMotionValue(minHeight),
        size: resolveMotionValue(size),
        center,
        _constraints,
        widthType,
        heightType,
    }
    return constraintProps
}

/** @public */
export interface FrameProps
    extends BackgroundProperties,
        VisualProperties,
        Omit<MotionDivProps, "color">,
        CSSTransformProperties,
        LayerProps,
        FrameLayoutProperties,
        ConstraintConfiguration,
        BaseFrameProps {
    /** @internal */
    __layoutId?: string | undefined
    /** @internal */
    __fromCanvasComponent?: boolean
}

export const defaultFrameRect = { x: 0, y: 0, width: 200, height: 200 }

function useStyleAndRect(props: Partial<FrameProps>): [MotionStyle, Rect | null] {
    injectComponentCSSRules()
    const inCodeComponent = Boolean(useContext(ComponentContainerContext))

    const { style, _initialStyle, __fromCanvasComponent, size } = props
    const unwrappedProps = unwrapFrameProps(props)
    const constraintsRect = useConstraints(unwrappedProps)

    const defaultStyle: MotionStyle = {
        display: "block",
        flexShrink: 0,
        userSelect: "none",
    }

    if (!props.__fromCanvasComponent) {
        // XXX: this is hack until we find a better solution
        defaultStyle.backgroundColor = props.background === undefined ? "rgba(0, 170, 255, 0.3)" : undefined
    }

    if (!hasEvents(props)) {
        defaultStyle.pointerEvents = "none"
    }

    const addTextCentering =
        React.Children.count(props.children) > 0 &&
        React.Children.toArray(props.children).every(child => {
            return typeof child === "string" || typeof child === "number"
        })
    const centerTextStyle = addTextCentering && {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    }

    const propsStyle = getStyleForFrameProps(props)

    if (size === undefined && !__fromCanvasComponent) {
        if (!hasLeftAndRight(propsStyle)) {
            defaultStyle.width = defaultFrameRect.width
        }

        if (!hasTopAndBottom(propsStyle)) {
            defaultStyle.height = defaultFrameRect.height
        }
    }

    if (unwrappedProps.minWidth !== undefined) {
        defaultStyle.minWidth = unwrappedProps.minWidth
    }

    if (unwrappedProps.minHeight !== undefined) {
        defaultStyle.minHeight = unwrappedProps.minHeight
    }

    let constraintsStyle: MotionStyle = {}

    if (constraintsEnabled(unwrappedProps)) {
        // When we have an auto-sized dimension, the constraints rect will be
        // based on stale cached values, so we won't use it.
        if (constraintsRect && !isAutoSized(props)) {
            constraintsStyle = {
                left: constraintsRect.x,
                top: constraintsRect.y,
                width: constraintsRect.width,
                height: constraintsRect.height,
                right: undefined,
                bottom: undefined,
            }
        }
    }

    // In theory we should not have constraints and props styles at the same time
    // because we use constraints internally in vekter and top level props are only for usage from customer code
    //
    // In practice we have it with code overrides
    // But we take `propsStyle` priority in any case now
    Object.assign(defaultStyle, centerTextStyle, _initialStyle, propsStyle, constraintsStyle, style)

    Layer.applyWillChange(props, defaultStyle, true)

    let resultStyle = defaultStyle
    if (!defaultStyle.transform) {
        // Reset the transform explicitly, because Framer Motion will not treat undefined values as 0 and still generate a transform
        resultStyle = { x: 0, y: 0, ...defaultStyle }
    }

    if (props.positionSticky) {
        const onCanvas = RenderTarget.current() === RenderTarget.canvas
        if (!onCanvas || inCodeComponent) {
            resultStyle.position = "sticky"
            resultStyle.willChange = "transform"
            resultStyle.zIndex = 1
            resultStyle.top = props.positionStickyTop
            resultStyle.right = props.positionStickyRight
            resultStyle.bottom = props.positionStickyBottom
            resultStyle.left = props.positionStickyLeft
        }
    }

    return [resultStyle, constraintsRect]
}

// These properties are considered valid React DOM props because they're valid
// SVG props, so we need to manually exclude them.
const filteredProps = new Set([
    "width",
    "height",
    "opacity",
    "overflow",
    "radius",
    "background",
    "color",
    "x",
    "y",
    "z",
    "rotate",
    "rotateX",
    "rotateY",
    "rotateZ",
    "scale",
    "scaleX",
    "scaleY",
    "skew",
    "skewX",
    "skewY",
    "originX",
    "originY",
    "originZ",
])

function getMotionProps(props: Partial<FrameProps>): MotionDivProps {
    const motionProps = {}

    for (const key in props) {
        const isValid = isValidMotionProp(key) || isPropValid(key)
        if (isValid && !filteredProps.has(key)) {
            motionProps[key] = props[key]

            /**
             * Support legacy layout animation props
             */
        } else if (key === "positionTransition" || key === "layoutTransition") {
            motionProps["layout"] = true
            if (typeof props[key] !== "boolean" && !props.transition) {
                motionProps["transition"] = props[key]
            }
        }
    }

    return motionProps
}

function hasDataFramerName(props: Partial<FrameProps>) {
    return "data-framer-name" in props
}

/** @internal */
export const FrameWithMotion = forwardRef<HTMLDivElement, Partial<FrameProps>>(function FrameWithMotion(props, ref) {
    if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()
    const { visible = true } = props
    if (!visible) return null

    return <VisibleFrame {...props} ref={ref} />
})

const VisibleFrame = forwardRef(function VisibleFrame(
    props: Partial<FrameProps>,
    forwardedRef: RefObject<HTMLDivElement> | null
) {
    const { _border, name, center, border } = props
    const { props: propsWithOverrides, children } = processOverrideForwarding(props)
    const motionProps = getMotionProps(propsWithOverrides)
    const layoutId = useLayoutId(props)
    const cursor = getCursorFromEvents(props)
    const fallbackRef = useRef<HTMLDivElement>(null)
    const ref = forwardedRef ?? fallbackRef

    const dataProps = {
        "data-framer-component-type": "Frame",
        "data-framer-cursor": cursor,
        "data-framer-highlight": cursor === "pointer" ? true : undefined,
        "data-layoutid": layoutId,
    }

    // Vekter provides the `data-framer-name` prop. However to maintain api
    // compatibility when Frame is used in code, set `data-framer-name` if
    // `name` is provided and `data-framer-name` is not.
    if (!hasDataFramerName(props) && name) {
        dataProps["data-framer-name"] = name
    }

    const [currentStyle, rect] = useStyleAndRect(propsWithOverrides)

    const unwrappedProps = unwrapFrameProps(propsWithOverrides)
    const autoSized = isAutoSized(unwrappedProps)

    if (center && !(rect && !autoSized && constraintsEnabled(unwrappedProps))) {
        motionProps.transformTemplate = transformTemplate(center)
        Object.assign(dataProps, layoutHintDataPropsForCenter(center))
    } else {
        motionProps.transformTemplate = transformTemplate(false)
    }

    useMeasureLayout(props, ref)

    const backgroundImage = backgroundImageFromProps(props)

    // The parentSize resolved here won't be used if a parent further up the
    // tree disabled parent size resolution (e.g. when rendering inside a code component)
    const inCodeComponent = Boolean(useContext(ComponentContainerContext))
    const parentSize = resolveParentSize(propsWithOverrides, unwrappedProps, rect, inCodeComponent)
    const wrappedContent = useProvideParentSize(
        <>
            {backgroundImage ? (
                <BackgroundImageComponent
                    image={backgroundImage}
                    containerSize={rect ?? undefined}
                    nodeId={props.id && nodeIdFromString(props.id)}
                    layoutId={layoutId}
                />
            ) : null}

            {children}

            <Border {..._border} border={border} layoutId={layoutId} />
        </>,
        parentSize
    )

    return (
        <motion.div
            {...dataProps}
            {...motionProps}
            layoutId={layoutId}
            style={currentStyle}
            ref={ref}
            transformValues={transformValues}
        >
            {wrappedContent}
        </motion.div>
    )
})

function resolveParentSize(
    props: Partial<FrameProps>,
    unwrappedProps: Partial<NewConstraintProperties>,
    rect: Rect | null,
    inCodeComponent: boolean
): ParentSize {
    if (inCodeComponent) {
        return rect ? { width: rect.width, height: rect.height } : ParentSizeState.Disabled
    }

    const { _usesDOMRect } = props
    const {
        widthType = DimensionType.FixedNumber,
        heightType = DimensionType.FixedNumber,
        width,
        height,
    } = unwrappedProps

    // The constraints rect might be based on stale layout information, or
    // return defaults if this is a DOM layout node, so we won't use it unless
    // `usesDOMRect` is also false
    if (rect && !_usesDOMRect) {
        return rect
    }

    // Even if we can't resolve a full rect (e.g. when the node relies on DOM
    // layout for positioning), we might still be able to provide a size if this
    // is a fixed-size node
    if (
        widthType === DimensionType.FixedNumber &&
        heightType === DimensionType.FixedNumber &&
        typeof width === "number" &&
        typeof height === "number"
    ) {
        return { width, height }
    }

    // If this is a DOM layout node, we need to return DisabledForCurrentLevel,
    // instead of Disabled, because that way children will still render using
    // DOM layout, but we won't prevent any descendants from using a resolved
    // parent size further down the hierarchy
    if (_usesDOMRect) {
        return ParentSizeState.DisabledForCurrentLevel
    }

    // If all else fails, return unknown
    return ParentSizeState.Unknown
}

function isAutoSized({
    width,
    height,
}: {
    width?: string | number | MotionValue
    height?: string | number | MotionValue
}) {
    return width === "auto" || width === "min-content" || height === "auto" || height === "min-content"
}
