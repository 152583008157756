import * as React from "react"
import { ConvertColor } from "../types/Color"

/**
 * @internal
 */
export function _getCSSTextColorFromStyle(css: React.CSSProperties) {
    let color

    if (typeof css["webkitTextFillColor"] === "string") {
        color = css["webkitTextFillColor"]
    }
    if (typeof css.WebkitTextFillColor === "string") {
        color = css.WebkitTextFillColor
    }
    if (typeof css.color === "string") {
        color = css.color
    }
    if (color) {
        return ConvertColor.toRgbString(color)
    }
}
