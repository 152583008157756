import { Store, Subscriber, ActionMap, BoundActionMap } from "./types"

/**
 * Take the user-defined `actions` map and return a version of the same functions
 * that update the store.
 *
 * @param get - Store data getter
 * @param set - Store data setter
 * @param actions - The actions to bind to the store
 */
function bindActionsToStore<State, Actions extends ActionMap<State>>(
    get: Store<State, Actions>["get"],
    set: Store<State, Actions>["set"],
    actions: Actions
): BoundActionMap<State, Actions> {
    const boundActions: Partial<BoundActionMap<State, Actions>> = {}

    for (const key in actions) {
        boundActions[key] = (data: any) => set(actions[key](get(), data))
    }

    return boundActions as BoundActionMap<State, Actions>
}

/**
 * A data store instance. `useData` can create and access multiple different data stores depending
 * on the `storeId` passed to it (if any).
 *
 * @param initialState - The initial state of the store.
 * @param unboundActions - Optional map of actions to make available for manipulating the data store.
 */
export function createStore<State, Actions extends ActionMap<State>>(
    initialState: State,
    unboundActions?: Actions
): Store<State, Actions> {
    let state = initialState
    let version = 0
    const subscribers = new Set()
    const notifySubscriber = (sub: Subscriber) => sub(version)

    const get = () => state
    const set = (latestState: State) => {
        version++
        state = latestState
        subscribers.forEach(notifySubscriber)
    }

    const actions = unboundActions ? bindActionsToStore(get, set, unboundActions) : set

    return {
        get,
        set,
        getVersion: () => version,
        getActions: () => actions,
        subscribe: sub => {
            subscribers.add(sub)
            return () => subscribers.delete(sub)
        },
    }
}
