import { FrameProps } from "../presentation/Frame/FrameWithMotion"
import { MotionStyle } from "framer-motion"

export function extractStyleFromProps(
    props: Partial<FrameProps>,
    name: keyof FrameProps,
    styleRef: MotionStyle,
    into?: keyof MotionStyle
) {
    if (into === undefined) {
        into = name as keyof MotionStyle
    }
    if (props[name] !== undefined) {
        // @ts-ignore Work around a “union type that is too complex to represent” error
        styleRef[into] = props[name]
        return
    }
}
