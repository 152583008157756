import { safeWindow } from "../../utils/safeWindow"

/**
 *  @internal
 *  @deprecated - don't use outside of Library anymore.
 */
export function throttle<T extends any[]>(fn: (...args: T) => void, time: number): (...args: T) => void {
    let previous = 0
    let timeout: number | undefined

    const later = (...args: T) => {
        previous = Date.now()
        timeout = undefined
        fn(...args)
    }

    return (...args: T) => {
        const now = Date.now()
        const remaining = time - (now - previous)
        if (remaining <= 0 || remaining > time) {
            if (timeout) {
                safeWindow.clearTimeout(timeout)
                timeout = undefined
            }
            previous = now
            fn(...args)
        } else if (!timeout) {
            timeout = safeWindow.setTimeout(later, remaining, ...args)
        }
    }
}
