export * from "framer-motion"
export {
    useDeprecatedAnimatedState as useAnimatedState,
    useDeprecatedInvertedScale as useInvertedScale,
} from "framer-motion"
import "./animation/Motion/addChildHack"

export {
    Scroll,
    ScrollProps,
    NativeScrollProps,
    EmulatedScrollProps,
    ScrollEvents,
    ScrollConfig,
    Page,
    PageEvents,
    PageProps,
    PageProperties,
    PageEffect,
    PageEffectInfo,
    PageAlignment,
    Draggable,
    Stack,
    StackProperties,
    StackSpecificProps,
    Navigation,
    useIsInCurrentNavigationTarget,
    useOnCurrentTargetChange,
} from "./components"
export { WithNavigator } from "./components/hoc/WithNavigator"
export { withMeasuredSize } from "./components/hoc/withMeasuredSize"
export { LayoutIdContext, AutomaticLayoutIds } from "./components/AnimateLayout/LayoutIdContext"
export { makePaddingString, paddingFromProps } from "./components/utils/paddingFromProps"
export { toFlexDirection, toJustifyOrAlignment, isGapEnabled, fraction, isFractionDimension } from "./components"

export { AnyInterpolation, ValueInterpolation } from "./interpolation"

export { Animatable, AnimatableObject, Cancel, DeprecatedAnimationTarget, isAnimatable } from "./animation/Animatable"
export { animate } from "./animation/animate"
export { FramerAnimation } from "./animation/FramerAnimation"
export { BezierAnimator, SpringAnimator } from "./animation/Animators"

export { FramerEvent, FramerEventListener, FramerEventSession } from "./events"

export { LineJoin, LineCap } from "./render/types/Stroke"
export {
    getConfigFromPreviewURL as getConfigFromURL,
    getConfigFromPreviewURL,
    getConfigFromVekterURL,
} from "./render/utils/getConfigFromURL"
export { serverURL } from "./render/assetResolver/serverURL"
export { memoize } from "./utils/memoize"
export { InternalID } from "./utils/internalId"
export { LinearGradientElementProperties } from "./render/utils/elementPropertiesForGradient"
export { frameFromElement, frameFromElements, dispatchKeyDownEvent, DOM } from "./render/utils/dom"
export { FillProperties } from "./render/traits/Fill"
export { FilterProperties, FilterNumberProperties } from "./render/traits/Filters"
export { BackgroundFilterProperties } from "./render/traits/BackdropFilters"
export { RadiusProperties } from "./render/traits/Radius"
export type { BlendingProperties, BlendingMode } from "./render/traits/Blending"
export type { Background, BackgroundProperties } from "./render/traits/Background"
export { ImageFit, BackgroundImage } from "./render/types/BackgroundImage"
export { WithOpacity, withOpacity } from "./render/traits/Opacity"
export { DeprecatedVisualProperties } from "./render/style/collectVisualStyleFromProps"
export { WithShape, withShape } from "./render/traits/Shape"
export { BoxShadowProperties } from "./render/traits/BoxShadow"
export { gradientForShape } from "./render/utils/gradientForShape"
export { debounce } from "./render/utils/debounce"
export { throttle } from "./render/utils/throttle"
export { imageUrlForAsset } from "./render/utils/imageUrlForAsset"
export { imagePatternPropsForFill } from "./render/utils/imagePatternPropsForFill"
export { isDesignDefinition, isOverride, isReactDefinition } from "./render/componentLoader"
export type {
    ComponentIdentifier,
    ComponentLoader,
    ComponentMap,
    TokenDefinition,
    TokenMap,
} from "./render/componentLoader"
export { localPackageFallbackIdentifier } from "./render/componentLoader/package"
export { ControlType, ControlIcon, Keyable } from "./render/types/PropertyControls"
export type {
    ControlDescription,
    CompactControlsDescription,
    BooleanControlDescription,
    BaseControlDescription,
    NumberControlDescription,
    StringControlDescription,
    FusedNumberControlDescription,
    EnumControlDescription,
    SegmentedEnumControlDescription,
    ColorControlDescription,
    ImageControlDescription,
    FileControlDescription,
    ComponentInstanceDescription,
    EventHandlerControlDescription,
    TransitionControlDescription,
    ArrayControlDescription,
    ArrayItemControlDescription,
    ObjectControlDescription,
    ObjectPropertyControlDescription,
    PropertyControls,
} from "./render/types/PropertyControls"
export { WithFractionOfFreeSpace } from "./render/traits/FreeSpace"
export { isOfAnnotatedType, annotateTypeOnStringify } from "./render/utils/annotateTypeOnStringify"
export { PathSegment } from "./render/types/PathSegment"
export { PathSegments } from "./render/types/PathSegments"
export { WithPath, withPath, WithPaths, isStraightCurve, pathDefaults, toSVGPath } from "./render/traits/Path"
export { BoxShadow, Shadow } from "./render/types/Shadow"
export { StrokeAlignment } from "./render/types/StrokeAlignment"
export { Rect } from "./render/types/Rect"
export { Size } from "./render/types/Size"
export { Line } from "./render/types/Line"
export { Point } from "./render/types/Point"
export {
    Gradient,
    LinearGradient,
    LinearGradientBase,
    RadialGradient,
    RadialGradientBase,
} from "./render/types/Gradient"
export { Frame, FrameWithMotion, DeprecatedFrameWithEvents } from "./render/presentation/Frame"
export { Image } from "./render/presentation/Image"
export type {
    FrameProps,
    DeprecatedFrame,
    DeprecatedFrameProperties,
    DeprecatedFrameWithEventsProps,
    FrameLayoutProperties,
    DeprecatedCoreFrameProps,
    BaseFrameProps,
    CSSTransformProperties,
    VisualProperties,
} from "./render/presentation/Frame"
export { cssBackgroundSize } from "./render/presentation/Frame/DeprecatedFrame"
export { Layer, LayerProps, IdentityProps, DOMLayoutProps } from "./render/presentation/Layer"
export { SVG, SVGProperties } from "./render/presentation/SVG"
export { Text, TextProperties, TextAlignment, TextVerticalAlignment } from "./render/presentation/Text"
export { TextColorProperties } from "./render/traits/TextColor"
export { Vector, VectorProperties } from "./render/presentation/Vector"
export { VectorGroup, VectorGroupProperties } from "./render/presentation/VectorGroup"
export { ComponentContainerContext } from "./render/presentation/ComponentContainerContext"
export { DeprecatedComponentContainer } from "./render/presentation/DeprecatedComponentContainer"
export { getMeasurableCodeComponentChildren } from "./render/utils/getMeasurableCodeComponentChildren"
export { TransformValues } from "./render/types/TransformValues"
export {
    ConstraintValues,
    ConstraintMask,
    DimensionType,
    valueToDimensionType,
    getMergedConstraintsProps,
} from "./render/types/Constraints"
export type { DefaultProps, ConstraintProperties, ConstraintPercentage } from "./render/types/Constraints"

export {
    ProvideParentSize,
    NewConstraintProperties,
    calculateRect,
    ParentSizeState,
    constraintsEnabled,
    PositionStickyProperties,
} from "./render/types/NewConstraints"
export { Color, ConvertColor, ColorMixOptions } from "./render/types/Color"
export { ColorFormat, ColorMixModelType } from "./render/types/Color/types"
export type { ColorRGBA, ColorHSLA, ColorHSL, ColorHSVA, IncomingColor } from "./render/types/Color/types"
export type { BorderStyle, BorderProperties } from "./render/style/BorderComponent"
export { isEqual } from "./render/utils/isEqual"
// XXX: Workaround for https://github.com/microsoft/rushstack/issues/1029
//
// When fixed, replace with `export { environment } from "./utils"`, and remove
// the `environment` export from ./utils/environment.ts
export { environment } from "./utils/environment"

export { isFiniteNumber, finiteNumber } from "./render/utils/isFiniteNumber"
export { isShallowEqualArray } from "./render/utils/isShallowEqualArray"
export { roundedNumber, roundedNumberString, roundWithOffset } from "./render/utils/roundedNumber"
export { transformString } from "./render/utils/transformString"
export { transformTemplate } from "./render/utils/transformTemplate"
export { isMotionValue } from "./render/utils/isMotionValue"
export { localShadowFrame } from "./render/style/shadow"
export { PresentationTree, convertPresentationTree } from "./render/presentation/PresentationTree"
export {
    RenderTarget,
    useRenderEnvironment,
    executeInRenderEnvironment,
    setGlobalRenderEnvironment,
} from "./render/types/RenderEnvironment"
export {
    NavigationInterface,
    NavigationProps,
    NavigationConsumer,
    NavigationCallbackProvider,
} from "./components/Navigation"
export {
    NavigationTransition,
    NavigationTransitionPosition,
    NavigationTransitionSide,
    NavigationTransitionAnimation,
    NavigationTransitionAppearsFrom,
    NavigationTransitionBackdropColor,
    FadeTransitionOptions,
    PushTransitionOptions,
    ModalTransitionOptions,
    OverlayTransitionOptions,
    FlipTransitionOptions,
} from "./components/NavigationTransitions"
export {
    NavigateTo,
    NavigationLink,
    NavigationTarget,
    NavigationTransitionType,
    NavigationTransitionDirection,
} from "./render/types/NavigationLink"
export { useNavigation } from "./components/useNavigation"
export { StackDirection, StackDistribution, StackAlignment } from "./components/Stack/types"

export { backgroundImageFromProps } from "./render/style/backgroundImageFromProps"
export { collectVisualStyleFromProps } from "./render/style/collectVisualStyleFromProps"
export { _getCSSTextColorFromStyle } from "./render/style/getCSSTextColorFromStyle"

export { createDesignComponent, CanvasStore, PropertyTree, RenderNodeProvider } from "./render/DesignComponentWrapper"

export { CustomProperties, CustomPropertiesContext } from "./render/presentation/CustomProperties"

export { fontStore } from "./render/fonts/fontStore"
export { loadFont } from "./render/fonts/loadFont"
export { parseVariant } from "./render/fonts/utils"
export {
    WebFontLocator,
    Typeface,
    TypefaceSelector,
    Font,
    TypefaceSourceName,
    TypefaceSourceNames,
    TypefaceLocator,
    ReadonlyFont,
    ReadonlyTypeface,
} from "./render/fonts/types"
export { forceLayerBackingWithCSSProperties } from "./render/utils/setLayerBacked"
export { systemTypefaceName } from "./render/fonts/LocalFontSource"
export { EmptyState } from "./components/EmptyState"
export { useMeasureLayout } from "./render/utils/useMeasureLayout"
export { withGeneratedLayoutId } from "./render/utils/withGeneratedLayoutId"

export { ObservableObject } from "./data/ObservableObject"
export { Data } from "./data/Data"
export { createData, DataContext } from "./data/useData"
export { Override, OverrideFunction, OverrideObject, WithOverride } from "./deprecated/WithOverride"
export { Action, ActionHandler, ActionControlDescription, ActionControls } from "./render/types/Action"
export { addActionControls } from "./utils/addActionControls"
export type { ActionInfo } from "./utils/addActionControls"

export { useObserveData, DataObserver, DataObserverContext } from "./deprecated/DataObserver"
export { PropertyStore } from "./data/PropertyStore"

export { loadJSON } from "./utils/network"
export { print } from "./utils/print"
export { _injectRuntime, Runtime } from "./utils/runtimeInjection"
export { addPropertyControls, getPropertyControls } from "./utils/addPropertyControls"
export { addFonts, getFonts, ComponentFont } from "./utils/addFonts"
export { version } from "./version"

export { MotionSetup } from "./animation/Motion/MotionSetup"
export { startAnimation } from "./animation/Motion/startAnimation"

export { useAddVariantProps } from "./modules/useAddVariantProps"
export { useVariantState, CycleVariantState, VariantSelector } from "./modules/useVariantState"
export { withCSS } from "./modules/withCSS"
export { Container } from "./modules/Container"
export { callEach } from "./modules/callEach"
export { useOnVariantChange, useOnAppear } from "./modules/useOnVariantChange"
export { useActiveVariantCallback, useActiveTargetCallback } from "./modules/useActiveVariantCallback"
export { useIsOnFramerCanvas } from "./modules/useIsOnFramerCanvas"
export { useNavigate, lazy } from "./modules/useNavigate"
export { cx } from "./modules/cx"
export { LayoutGroup } from "./modules/LayoutGroup"
export { useHotkey } from "./modules/useHotkey"
export { useGamepad, isFramerGamepadKeydownData, FramerGamepadKeydownData, GamepadContext } from "./modules/useGamepad"

import { MainLoop } from "./core/Loop"
export { MainLoop }

// Only start the loop if this is the library
if (process.env.BUILD_NAME === "framer") {
    MainLoop.start()
}
