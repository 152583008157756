import { defaultFontSelector } from "./fonts"
import { googleFontSelectorPrefix, GoogleFontSource } from "./GoogleFontSource"
import { LocalFontSource } from "./LocalFontSource"
import {
    Asset,
    ReadonlyFont,
    Font,
    WebFontLocator,
    FontVariant,
    Typeface,
    TypefaceLocator,
    TypefaceSourceNames,
    ReadonlyTypeface,
    DraftFontProperties,
} from "./types"
import { parseVariant } from "./utils"
import { CustomFontSource, customFontSelectorPrefix } from "./CustomFontSource"
import { loadFont, isFontReady } from "./loadFont"

import { runtime } from "../../utils/runtimeInjection"

/**
 * Used to differentiate between requests that are immediately fulfilled,
 * because the font was already loaded, and those that resulted in a newly
 * loaded font
 *
 * @internal
 */
export enum LoadFontResult {
    AlreadyLoaded,
    Loaded,
}

/**
 * Stores all available fonts, whether they are currently loaded or not
 * Provides APIs to import, add and resolve fonts and font selectors
 * Model:
 * `FontStore` (single instance available via `fontStore`)
 *   `FontSource` (local/google)
 *     `Typeface` (font family and its variants)
 *       `Font` (font family with a specific variant)
 * Every `Font` has a `selector` (string), which is a unique identifier of a font
 * Google web fonts provide consistent naming for fonts,
 * so it's also possible to `parseFontSelector()` and get some info about a web font from only its selector
 *
 * @internal
 */
export class FontStore {
    private bySelector = new Map<string, ReadonlyFont>()
    private getGoogleFontsListPromise: Promise<google.fonts.WebfontFamily[]>
    private loadedSelectors = new Set<string>()
    defaultFont: ReadonlyFont | null

    constructor() {
        this.local = new LocalFontSource()
        this.google = new GoogleFontSource()
        this.custom = new CustomFontSource()

        this.bySelector = new Map<string, ReadonlyFont>()
        this.importLocalFonts()

        // Load the default font if needed & possible
        this.defaultFont = this.getFontBySelector(defaultFontSelector)
        if (this.defaultFont) {
            this.loadFont(this.defaultFont)
        }
    }

    local: LocalFontSource
    google: GoogleFontSource
    custom: CustomFontSource

    private addFont(font: Font) {
        this.bySelector.set(font.selector, font)
    }

    getAvailableFonts(): ReadonlyFont[] {
        return Array.from(this.bySelector.values())
    }

    private importLocalFonts() {
        this.local.importFonts().forEach(font => {
            this.addFont(font)
            // Immediately “load” fonts (as they require no real loading, except Inter)
            if (!this.local.interTypefaceSelectors.has(font.selector)) {
                this.loadFont(font)
            }
        })
    }

    async importGoogleFonts(): Promise<google.fonts.WebfontFamily[]> {
        if (!this.getGoogleFontsListPromise) {
            this.getGoogleFontsListPromise = runtime.fetchGoogleFontsList()
            const googleFonts = await this.getGoogleFontsListPromise
            this.google.importFonts(googleFonts).forEach(locator => {
                const font = this.createGoogleFont(locator)
                this.addFont(font)
            })
        }
        return this.getGoogleFontsListPromise
    }

    importCustomFonts(assets: readonly Asset[]) {
        // Clear custom fonts from the list as they might have been deleted from assets
        this.bySelector.forEach((_, key) => {
            if (key.startsWith(customFontSelectorPrefix)) {
                this.bySelector.delete(key)
            }
        })
        this.custom.importFonts(assets).forEach(font => this.addFont(font))
    }

    getTypeface(info: TypefaceLocator): ReadonlyTypeface | null {
        const typeface = this[info.source].getTypefaceByFamily(info.family)
        return typeface
    }

    getFontBySelector(selector: string, createFont = true): ReadonlyFont | null {
        if (selector.startsWith(customFontSelectorPrefix)) {
            return this.custom.getFontBySelector(selector, createFont)
        }
        return this.bySelector.get(selector) || null
    }

    // Function called by draft to get font properties for a selector, before the (google) font is available in the store
    // It replaces a previous function that created Font instances and added them to the store
    // on the fly while rendering drafts, which caused issues (overriding real google font info with fake instances with partial data).
    // Ideally this should not happen, but that's a fix for another day
    getDraftPropertiesBySelector(selector: string): DraftFontProperties | null {
        const font = this.getFontBySelector(selector)
        if (font) {
            return {
                style: font.style,
                weight: font.weight,
                variant: font.variant,
                family: font.typeface.family,
                source: font.typeface.source,
            }
        }
        // If this is an unknown selector, attempt to parse it as a google font selector
        const locator = this.google.parseSelector(selector)
        if (locator) {
            const fontVariant = parseVariant(locator.variant)
            if (fontVariant) {
                return {
                    style: fontVariant.style,
                    weight: fontVariant.weight,
                    variant: locator.variant,
                    family: locator.family,
                    source: TypefaceSourceNames.Google,
                }
            }
        }
        return null
    }

    createGoogleFont = (locator: WebFontLocator): Font => {
        const { source, family, variant, file } = locator

        // Find the parent Typeface for the font (or create it)
        let typeface = this.getTypeface(locator) as Typeface | null
        if (!typeface) {
            typeface = this[source].createTypeface(family)
        }

        const variantInfo: Partial<FontVariant> = parseVariant(variant) || {}
        const { weight, style } = variantInfo
        const selector = `GF;${family}-${variant}`

        const font = {
            typeface,
            variant,
            selector,
            weight,
            style,
            file,
        }
        typeface.fonts.push(font)
        return font
    }

    isSelectorLoaded(selector: string): boolean {
        return this.loadedSelectors.has(selector)
    }

    /**
     * Load all fonts for a typeface
     * */
    async loadTypeface(typeface: ReadonlyTypeface): Promise<void> {
        await Promise.all(typeface.fonts.map(f => this.loadFont(f)))
    }

    /**
     * Load a single font
     * */
    private async loadFont(font: Font | ReadonlyFont): Promise<LoadFontResult> {
        if (this.isSelectorLoaded(font.selector)) {
            return LoadFontResult.AlreadyLoaded
        }

        if (font.typeface.source === TypefaceSourceNames.Local) {
            // In case of a local font, we can safely assume it's loaded, except for
            // the Inter font, which is loaded via an external CSS file. Loading will be
            // initiated automatically by the browser, we only need to wait until it's ready.
            // NOTE: Skip for tests and assume Inter is loaded.
            if (this.local.interTypefaceSelectors.has(font.selector) && process.env.NODE_ENV !== "test") {
                await isFontReady(font.typeface.family, font.style, font.weight)
            }
            this.loadedSelectors.add(font.selector)
            return LoadFontResult.Loaded
        }

        // Load custom or Google font
        if (!font.file) {
            return Promise.reject(`Unable to load font: ${font.selector}`)
        }
        await loadFont(
            {
                family: font.typeface.family,
                url: font.file,
                weight: font.weight,
                style: font.style,
            },
            document
        )
        this.loadedSelectors.add(font.selector)
        return LoadFontResult.Loaded
    }

    async loadWebFontsFromSelectors(selectors: string[]): Promise<PromiseSettledResult<LoadFontResult>[]> {
        // In case we are loading a Google font, make sure the list of
        // Google fonts has been imported in the store
        if (selectors.some(s => s.startsWith(googleFontSelectorPrefix))) {
            await this.importGoogleFonts()
        }

        // Filter out all unknown fonts. This means that not every selector
        // that was requested might be loaded!
        const fonts = selectors.map(s => this.bySelector.get(s)).filter((f): f is ReadonlyFont => !!f)

        // Trigger the loading of all fonts. We’re using `allSettled` here
        // (polyfilled below) to make sure as many as possible are loaded. Fonts
        // that have failed before will immediately reject.
        return Promise.allSettled(fonts.map(f => this.loadFont(f)))
    }
}

/** @internal */
export const fontStore = new FontStore()

/** Promise.allSettled polyfill */
Promise.allSettled =
    Promise.allSettled ||
    ((promises: Promise<any>[]) =>
        Promise.all(
            promises.map(p =>
                p.then(v => ({ status: "fulfilled", value: v })).catch(e => ({ status: "rejected", reason: e }))
            )
        ))
