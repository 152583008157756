import * as React from "react"
import type { Ref } from "react"
import type { BackgroundImage } from "../types/BackgroundImage"
import { forwardRef } from "react"
import { MotionDivProps } from "./Frame/types"
import { BackgroundImageComponent } from "../style/BackgroundImageComponent"
import { motion, MotionStyle } from "framer-motion"

/** @internal */
interface BackgroundImageProps {
    background: Exclude<BackgroundImage, "src"> & { src: string | undefined }
}

/** @internal */
interface ImageProps extends MotionDivProps, BackgroundImageProps {}

/** @internal */
export const Image = forwardRef(function Image(props: Partial<ImageProps>, ref: Ref<HTMLDivElement>) {
    const { background, children, ...rest } = props
    const style: MotionStyle = { ...rest.style }

    if (background) {
        // Remove existing `background` props from style, when we are rendering a background asset.
        // This ensures that the background prop can be properly reset when removing the background-image.
        delete style.background
    }

    return (
        <motion.div {...rest} style={style} ref={ref}>
            {background && background.src ? <BackgroundImageComponent image={background} /> : null}
            {children}
        </motion.div>
    )
})
