import { useConstant } from "./useConstant"

function newMap<K, V>() {
    return new Map<K, V>()
}

/** Returns a constant instance of a Map */
export function useMap<K, V>() {
    return useConstant<Map<K, V>>(newMap)
}
