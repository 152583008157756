import * as React from "react"
import { LayoutTree } from "./SharedLayoutTree"
import { createAnimation, Config } from "./animation"
import { LayoutTreeContext, LayoutTreeContextProps } from "./TreeContext"
import {
    createBatcher,
    AxisBox2D,
    SharedLayoutContext,
    SharedLayoutSyncMethods,
    SyncLayoutLifecycles,
    VisualElement,
    createCrossfader,
    Transition,
    snapshotViewportBox,
} from "framer-motion"

const syncContextStub = {
    register: () => {},
    remove: () => {},
    add: () => {},
    flush: () => {},
    syncUpdate: () => {},
}

interface StackState {
    leadIsExiting: boolean
    lead?: VisualElement
    follow?: VisualElement
}

const TREE_ROOT_ID = "____treeroot"

/**
 * @internal
 */
export class AnimateLayoutTrees extends React.Component<{}, { foo: number }> {
    /**
     * The "lead" tree, the tree that we're animating to. This is usually the newest
     * tree to be added to the stack.
     */
    private lead: LayoutTree | undefined

    /**
     * The "follow" tree, the tree that we're animating from. This is usually the previous
     * lead tree.
     */
    private follow: LayoutTree | undefined
    private safeToRemoveTree: LayoutTree | undefined

    private scheduled = false
    private resetScheduled = false
    private layoutIdConfig = new Map<string, Config>()

    treeContext: LayoutTreeContextProps = {
        promoteTree: (...args) => this.promoteTree(...args),
        markTreeAsSafeToRemove: (tree: LayoutTree) => this.markTreeAsSafeToRemove(tree),
    }

    /**
     * Provide a dummy syncContext with a forceUpdate method to enable AnimatePresence
     * to remove trees when they are animating their removal.
     */
    syncContext: SharedLayoutSyncMethods = {
        ...syncContextStub,
        forceUpdate: () => {
            this.syncContext = {
                ...this.syncContext,
            }
            this.forceUpdate()
        },
    }

    private batch = createBatcher()

    /**
     * When a new LayoutTree is mounted, or becomes the lead tree,
     * it flags itself as the new lead tree in it's shouldComponentUpdate lifecycle method.
     * We then preform the batched writes and reads on the children of the new lead tree,
     * and any children of the previous lead tree that share a layoutId with children in the lead tree.
     * Finally, we flag that an animation is scheduled.
     *
     * The return value lets the LayoutTree know whether or not it should perform an update.
     * It shouldn't if for some reason the tree is already the lead tree, or shouldn't perform
     * a magic motion transition.
     */
    promoteTree(tree: LayoutTree, shouldAnimate: boolean, transition: any, resets?: boolean): boolean {
        if (tree === this.lead) return false
        const prevFollow = this.follow
        this.follow = this.lead
        this.lead = tree

        if (resets) this.resetScheduled = true

        if (!shouldAnimate) {
            this.startCrossfade(new Map([[TREE_ROOT_ID, {}]]), { type: false })
            return false
        }

        this.layoutIdConfig.clear()

        const currentStyle = {}

        /**
         * Write: Since we're supporting bounding box-distorting transforms, reset them before
         * measuring the bounding box.
         */
        for (const [layoutId, lead] of this.lead.children) {
            const follow = this.follow?.children.get(layoutId)

            snapshotRotate(layoutId, lead, follow, currentStyle)

            follow && resetRotate(follow)
            resetRotate(lead)
        }

        /**
         * Write: A tree might have a subset of components that need their transform reset
         * in order to correctly measure their children
         */
        this.lead.childrenToResetTransform.forEach(child => child.resetTransform())

        /**
         * Read: Snapshot children, register the follow's props to the ShouldAnimateContext,
         * and finally create an animation config.
         */
        for (const [layoutId, lead] of this.lead.children) {
            const follow = this.follow?.children.get(layoutId)

            follow && snapshotViewportBox(follow)
            snapshotViewportBox(lead)

            const prevViewportBox = follow?.prevViewportBox ? copyAxisBox(follow?.prevViewportBox) : undefined

            // Create the config.
            // In future this is where we would use the lead's transition if it were set in a timeline.
            const config: Config = {
                lead,
                current: currentStyle[layoutId],
                transition,
                prevViewportBox,
                shouldStackAnimate: follow ? true : false,
                prevParent: follow?.getProjectionParent(),
            }

            this.layoutIdConfig.set(layoutId, config)
        }

        /**
         * If this tree isn't either the new lead or follow, hide it
         */
        if (prevFollow !== this.lead && prevFollow !== this.follow) {
            prevFollow?.rootChild?.setVisibility(false)
        }

        this.scheduled = true
        return true
    }

    /**
     * Set a LayoutTree as being removed by framer-motion.
     * When AnimateLayoutTrees updates, we will call safeToRemove() on all children of this tree
     * so that AnimatePresence can remove the entire tree from the DOM when an exit animation completes.
     */
    markTreeAsSafeToRemove(tree: LayoutTree) {
        this.safeToRemoveTree = tree
    }

    /**
     * When AnimateLayoutTrees updates, if a tree has been marked as being safe to remove,
     * we iterate through that tree's children and call safeToRemove on them, ensuring that
     * AnimatePresence knows it shouldn't wait for those components to complete an exit animation.
     * We must call these functions in componentDidUpdate because safeToRemove won't be set on
     * children that are not yet being removed.
     */
    markTreeChildrenAsSafeToRemove(tree?: LayoutTree) {
        if (!tree) return
        for (const [_, child] of tree.children) {
            child.layoutSafeToRemove?.()
        }
    }

    /**
     * When a tree has promoted itself to be the new lead in it's shouldComponentUpdate lifecycle method,
     * and has scheduled animations, we perform those when the component has updated.
     */
    componentDidUpdate() {
        if (this.scheduled) this.startLayoutAnimation(this.resetScheduled)
        if (this.safeToRemoveTree) this.markTreeChildrenAsSafeToRemove(this.safeToRemoveTree)

        this.safeToRemoveTree = undefined
        this.scheduled = false
        this.resetScheduled = false
    }

    componentWillUnmount() {
        this.stopCrossfadeAnimation()
    }

    startLayoutAnimation(shouldReset: boolean) {
        const { lead, follow } = this
        const leadChildren = lead?.children
        const followChildren = follow?.children
        const toCrossfade = new Map<string, Config | undefined>()

        const handler: SyncLayoutLifecycles = {
            layoutReady: child => {
                /**
                 * If this component doesn't have a layoutId, don't include it in the tree animation.
                 */
                const layoutId = child.getLayoutId()
                if (layoutId === undefined) return

                const config = this.layoutIdConfig.get(layoutId)
                if (!config) return child.notifyLayoutReady({ shouldStackAnimate: false })

                /**
                 * Point all visual elements with this layoutId at the lead component. This
                 * ensures they all project into the same viewport box every frame and can crossfade.
                 */
                const followChild = followChildren?.get(layoutId)
                const leadChild = leadChildren?.get(layoutId)
                child.pointTo(leadChild ?? child)
                const isLead = Boolean(leadChildren && leadChild === child)

                /**
                 * If this is the lead child, schedule a crossfade animation and start the
                 * layout animation.
                 */
                if (isLead) {
                    if (followChild && leadChild) toCrossfade.set(layoutId, config)
                    child.notifyLayoutReady(
                        createAnimation({
                            ...config,
                            onComplete: () => {
                                followChild?.layoutSafeToRemove?.()
                            },
                        })
                    )
                }
            },
        }

        /**
         * Shared layout animations can be used without the AnimateSharedLayout wrapping component.
         * This requires some co-ordination across components to stop layout thrashing
         * and ensure measurements are taken at the correct time.
         *
         * Here we use that same mechanism of schedule/flush.
         */
        if (lead && leadChildren) {
            for (const [_, child] of leadChildren) this.batch.add(child)
            lead.layoutMayBeMutated = false
        }

        if (!shouldReset && follow && followChildren) {
            for (const [_, child] of followChildren) this.batch.add(child)
            follow.layoutMayBeMutated = true
        }

        this.batch.flush(handler)

        this.startCrossfade(toCrossfade)
    }

    /**
     * Keep track of the crossfade state between each layoutId follow/lead. A crossfade
     * isn't just an opacity fade but can also be a cross between border-radius and rotation.
     */
    stackCrossfaders = new Map([[TREE_ROOT_ID, createCrossfader()]])

    startCrossfade(toCrossfade: Map<string, Config | undefined>, transition?: any | undefined) {
        if (!this.lead?.rootChild) return

        const isExit = this.follow?.isExiting
        let rootTransition: Transition | undefined = transition
        const leadRoot = this.lead?.rootChild
        const followRoot = this.follow?.rootChild
        leadRoot?.setVisibility(true)
        followRoot?.setVisibility(true)

        const createCrossfadeAnimation = (config: Config, id: string) => {
            const followChild = this.follow?.children.get(id)
            const leadChild = this.lead?.children.get(id)

            /**
             * We'll handle the crossfade of any root children seperately.
             * Root children might not share an id.
             */
            if (leadChild === leadRoot || followChild === followRoot) {
                if (leadChild === leadRoot) rootTransition = config.transition
                return
            }

            /**
             * If this lead/follow state doesn't exist yet, create it
             */
            if (!this.stackCrossfaders.has(id)) {
                this.stackCrossfaders.set(id, createCrossfader())
            }

            const crossfader = this.stackCrossfaders.get(id)!

            /**
             * Update lead/follow
             */
            crossfader.setOptions({
                lead: leadChild,
                follow: followChild,
            })

            /**
             * Bind the crossfade state to the lead/follow elements
             */
            leadChild?.setCrossfader(crossfader)
            followChild?.setCrossfader(crossfader)

            /**
             * Trigger animation
             */
            crossfader.toLead(config?.transition)
        }

        toCrossfade.forEach(createCrossfadeAnimation)

        if (!leadRoot || !followRoot) return

        /**
         * Crossfade between root components.
         */
        const rootCrossfader = this.stackCrossfaders.get(TREE_ROOT_ID)!

        /**
         * Update lead/follow
         */
        rootCrossfader.setOptions({
            lead: leadRoot,
            follow: followRoot,
            preserveFollowOpacity: !isExit,
            crossfadeOpacity: true,
        })

        /**
         * Bind the crossfade state to the lead/follow elements
         */
        leadRoot.setCrossfader(rootCrossfader)
        followRoot.setCrossfader(rootCrossfader)
        const leadRootId = leadRoot.getLayoutId()
        const leadTransition = leadRootId
            ? this.layoutIdConfig.get(leadRootId)?.transition || rootTransition
            : rootTransition

        rootCrossfader.toLead(leadTransition)
    }

    stopCrossfadeAnimation() {
        this.stackCrossfaders.forEach(crossfader => crossfader.stop())
    }

    render() {
        return (
            <LayoutTreeContext.Provider value={this.treeContext}>
                <SharedLayoutContext.Provider value={this.syncContext}>
                    {this.props.children}
                </SharedLayoutContext.Provider>
            </LayoutTreeContext.Provider>
        )
    }
}

function copyAxisBox(box?: AxisBox2D) {
    if (!box) return undefined

    return {
        x: { ...box.x },
        y: { ...box.y },
    }
}

function snapshotRotate(
    layoutId: string,
    lead: VisualElement,
    follow: VisualElement | undefined,
    styleMap: Record<string, Record<string, any>>
) {
    const followRotate = follow?.getValue("rotate")
    const leadRotate = lead.getValue("rotate")
    styleMap[layoutId] = { rotate: leadRotate?.isAnimating() ? leadRotate.get() : followRotate?.get() || 0 }
}

const transformAxes = ["", "X", "Y", "Z"]

function resetRotate(child: VisualElement) {
    // If there's no detected rotation values, we can early return without a forced render.
    let hasRotate = false

    // Keep a record of all the values we've reset
    const resetValues = {}

    // Check the rotate value of all axes and reset to 0
    transformAxes.forEach(axis => {
        const key = "rotate" + axis

        // If this rotation doesn't exist as a motion value, then we don't
        // need to reset it
        if (!child.hasValue(key)) return

        hasRotate = true

        // Record the rotation and then temporarily set it to 0
        resetValues[key] = child.getStaticValue(key)
        child.setStaticValue(key, 0)
    })

    // If there's no rotation values, we don't need to do any more.
    if (!hasRotate) return

    // Force a render of this element to apply the transform with all rotations
    // set to 0.
    child.syncRender()

    // Put back all the values we reset
    for (const key in resetValues) {
        child.setStaticValue(key, resetValues[key])
    }

    // Schedule a render for the next frame. This ensures we won't visually
    // see the element with the reset rotate value applied.
    child.scheduleRender()
}

type MappedHTMLElement = [string, VisualElement]
