import { Animatable } from "../../animation/Animatable"
import { MotionStyle } from "framer-motion"

/** @public */
export interface WithOpacity {
    opacity: number | Animatable<number>
}

const key: keyof WithOpacity = "opacity"

/** @internal */
export function withOpacity(target: any): target is WithOpacity {
    return key in target
}

/** @internal */
export function collectOpacityFromProps(props: Partial<WithOpacity>, style: MotionStyle) {
    if (!withOpacity(props)) return
    const opacity = Animatable.getNumber(props.opacity)
    if (opacity === 1) return
    style.opacity = opacity
}
