export type ResolvablePromise<T> = Promise<T> & {
    /**
     * Note: the original `resolve` type is defined as `(value?: T) => void`,
     * which allows `resolve()`, but also creates a loophole — you could have
     * `Promise<string>` and still do `resolve()`. Here, we don't allow that,
     * so you'll have to be explicit with Promises that resolve with no value,
     * e.g., `newResolvablePromise<undefined>` + `resolve(undefined)`.
     */
    resolve: (value: T) => void
    reject: (reason?: unknown) => void
}

export function newResolvablePromise<T>(): ResolvablePromise<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let resolvePromise: any, rejectPromise: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promise: any = new Promise((resolve, reject) => {
        resolvePromise = resolve
        rejectPromise = reject
    })
    promise.resolve = resolvePromise
    promise.reject = rejectPromise
    return promise
}
