import { RenderTarget } from "../types/RenderEnvironment"
import { runtime } from "../../utils/runtimeInjection"

function parseURL(url: string, base?: string): URL | null {
    try {
        // Safari will throw a TypeError if base is undefined.
        return typeof base === "undefined" ? new URL(url) : new URL(url, base)
    } catch (err) {
        return null
    }
}

// FIXME: Use flatMap
// eslint-disable-next-line prefer-spread
const flatten = <T>(arrays: T[] | T[][]): T[] => [].concat.apply([], arrays)

type StringArray = string[]
type ServerURLArguments = StringArray | StringArray[]

function joinPaths(paths: ServerURLArguments): string {
    let res = ""
    for (const path of paths) {
        if (!path) continue
        if (res.length > 0 && !res.endsWith("/")) {
            res += "/"
        }
        if (Array.isArray(path)) {
            res += joinPaths(path)
        } else {
            res += encodeURIComponent(path).replace(/%2F/g, "/")
        }
    }
    return res
}

/**
 * NOT INTERNAL, being exposed from "framer/resources" as `url`
 * @internal
 */
export function serverURL(...paths: ServerURLArguments): string {
    // if only one of the arguments is not empty and is a valid URL
    const notEmptyPaths = flatten(paths).filter(Boolean)
    if (notEmptyPaths.length === 1 && parseURL(notEmptyPaths[0])) {
        return notEmptyPaths[0]
    }
    const path = joinPaths(paths)

    const resolvedAsset = runtime.assetResolver(path, {
        isFramerResourceURL: true,
        isExport: RenderTarget.current() === RenderTarget.export,
    })
    return resolvedAsset || ""
}
