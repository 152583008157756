import * as React from "react"
import { isObject } from "../utils/utils"
import { useOnCurrentTargetChange } from "../components"
import { useConstant } from "../components/utils/useConstant"

/** @internal */
export interface FramerGamepadKeydownData {
    key: string
    id: string
    mapping: Gamepad["mapping"]
}

/** @internal */
export function isFramerGamepadKeydownData(value: unknown): value is FramerGamepadKeydownData {
    return isObject(value) && value.mapping !== undefined
}

type GamepadKeydownHandler = (data: FramerGamepadKeydownData) => void

/**
 * Exported for testing.
 * @internal
 */
export function createGamepadPoller() {
    const callbacks = new Set<GamepadKeydownHandler>()

    let isConnected = false
    let isPolling: number | null = null
    let lastKey: string | null = null

    const startPolling = () => {
        const input = getFirstAvailableGamepadInput()
        // Don't do anything if no gamepad is connected
        if (!input) return

        const { gamepad, key } = input
        const { mapping, id } = gamepad

        // Each frame only knows its own last pressed key. If two continuous
        // frames both have gamepad events binding on the same key, we don't
        // want to fire two events in a row.
        if (key && lastKey !== key) callbacks.forEach(callback => callback({ key, mapping, id }))

        lastKey = key
        isPolling = window.requestAnimationFrame(startPolling)
    }

    const handleConnection = () => {
        if (isConnected || isPolling) return
        startPolling()
        isConnected = true
    }

    const stopPolling = () => {
        if (!isPolling) return
        window.cancelAnimationFrame(isPolling)
        isPolling = null
    }

    const handleDisconnection = () => {
        if (!isConnected) return
        stopPolling()
        isConnected = false
    }

    const setupAndStartPolling = () => {
        if (isPolling) return

        const gamepad = getFirstAvailableGamepadInput()
        if (!gamepad) {
            // @NOTE: gamepadconnected only exists on window
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/gamepadconnected_event
            window.addEventListener("gamepadconnected", handleConnection)
            return
        }

        // Even if we haven't connected yet, we need to make sure we disconnect
        // in the future.
        window.addEventListener("gamepaddisconnected", handleDisconnection)

        isConnected = true
        startPolling()
    }

    const cleanupAndStopPolling = () => {
        if (!isPolling) return

        // If the gamepad is already connected, then this event has no use
        // anymore; if the gamepad is not connected yet, we will only listen to
        // it when start polling. So we should remove the event listener anyway.
        window.removeEventListener("gamepadconnected", handleConnection)
        window.removeEventListener("gamepaddisconnected", handleDisconnection)

        stopPolling()
    }

    return {
        register(callback: (input: FramerGamepadKeydownData) => void) {
            if (callbacks.size === 0) setupAndStartPolling()
            callbacks.add(callback)
        },
        unregister(callback: (input: FramerGamepadKeydownData) => void) {
            callbacks.delete(callback)
            if (callbacks.size === 0) cleanupAndStopPolling()
        },
    }
}

/** @internal */
export const GamepadContext = React.createContext(createGamepadPoller())

/**
 * Return the first gamepad that has input together with the input key. If
 * there's no input, return the first available gamepad. If there's no gamepad
 * connected, return null.
 */
function getFirstAvailableGamepadInput(): { gamepad: Gamepad; key: string | null } | null {
    let firstConnectedGamepad: Gamepad | null = null
    const gamepads = navigator.getGamepads()
    for (const gamepad of gamepads) {
        if (!gamepad) continue

        if (!firstConnectedGamepad) {
            firstConnectedGamepad = gamepad
        }
        const axis = scanPressedAxis(gamepad)
        if (axis !== null) return { gamepad, key: axis }

        const button = scanPressedButton(gamepad)
        if (button !== null) return { gamepad, key: button }
    }

    if (firstConnectedGamepad) return { gamepad: firstConnectedGamepad, key: null }
    return null
}

function scanPressedAxis(gamepad: Gamepad): string | null {
    for (const [idx, axis] of gamepad.axes.entries()) {
        // console.log(idx, axis)
        // Normally axis 0&1 are allocated for the left stick, and 3&4 for the
        // right stick. Depending on the browser, some controllers can have more
        // than 4 axes. For example, in safari, a PS5 Dualsense controller has
        // its axis 4&5 for the left/right triggers, that are set to -1 when
        // idling. We don't want these axes to be taken as pressed.
        if (idx > 3) return null
        if (axis <= -0.5) return `Axis ${idx}-`
        if (axis > 0.5) return `Axis ${idx}+`
    }

    return null
}

function scanPressedButton(gamepad: Gamepad): string | null {
    for (const [idx, button] of gamepad.buttons.entries()) {
        if (isButtonPressed(button)) return `Button ${idx}`
    }
    return null
}

function isButtonPressed(button: GamepadButton): boolean {
    // button.value represents the current state of analog buttons
    return button.pressed === true || button.value > 0
}

/**
 * Register a callback to be executed when a gamepad button is pressed and the
 * registering component is in the current Framer navigation target. Optionally
 * provide a specific gamepad mapping.
 *
 *  @internal
 */
export function useGamepad(key: string, callback: () => void, options: { mapping?: string } = { mapping: "standard" }) {
    const context = React.useContext(GamepadContext)
    const mapping = useConstant(() => options.mapping)

    const cb = React.useCallback(
        (input: FramerGamepadKeydownData) => {
            if (key === input.key && mapping === input.mapping) callback()
        },
        [key, mapping, callback]
    )

    useOnCurrentTargetChange((isInTarget, isOverlayed) => {
        const isActive = isInTarget && !isOverlayed
        if (isActive) {
            context.register(cb)
        } else {
            context.unregister(cb)
        }

        // Unregister the callback when the screen unmounts. This is probably
        // unnecessary since we unregister the callback when the component
        // unmounts, but may catch instances where the screen is being unmounted
        // with AnimatePresence and hasn't yet been removed from the react tree.
        return () => context.unregister(cb)
    }, [])

    // Unregister the callback when unmounted.
    React.useEffect(() => {
        return () => context.unregister(cb)
    }, [cb, context])
}
