import * as React from "react"

/**
 * @beta
 */
export function useForceUpdate() {
    const [_, setForcedRenderCount] = React.useState(0)

    return React.useCallback(() => setForcedRenderCount(v => v + 1), [])
}
