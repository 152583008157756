import * as React from "react"
import { addScaleCorrection } from "framer-motion"
import { correctBorderScale } from "./autoValueHandlers"

addScaleCorrection({
    borderTopWidth: correctBorderScale("y"),
    borderLeftWidth: correctBorderScale("x"),
    borderRightWidth: correctBorderScale("x"),
    borderBottomWidth: correctBorderScale("y"),
})

interface Props {
    children: React.ReactNode
}

/** @public */
export function MotionSetup({ children }: Props) {
    return <>{children}</>
}
