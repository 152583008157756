import * as React from "react"

/**
 * Takes a CSS variable and attempts to lookup the value. Useful for retrieving
 * the original value when provided with a variable for animations or manipulation.
 * @returns the value string or null if not found.
 */
type CustomPropertiesLookup = (variable: string) => string | null

// Match the first portion of a CSS variable e.g. var(--token-123)
const VariableRegex = /var\(([^),]+)/

/**
 * Provides a lookup function `CustomPropertiesLookup` to consumers.
 * @internal
 */
export const CustomPropertiesContext = React.createContext<CustomPropertiesLookup>(() => null)

/**
 * Takes a CSS properties map of custom property to value and inserts them into the DOM.
 * Also sets up a CustomPropertiesContext provider to make a lookup function available
 * to all children.
 * @internal
 *
 * @internalremarks
 * This component was at the heart of a serious panning performance issue. Ensure any
 * refactoring never updates `value` every render.
 */
export class CustomProperties extends React.PureComponent<{
    customProperties: { [property: string]: string }
}> {
    lookup = (variable: string) => {
        const match = VariableRegex.exec(variable)
        const customProperty = match ? match[1].trim() : ""
        return this.props.customProperties[customProperty] || null
    }

    render() {
        const { children, customProperties } = this.props
        return (
            <CustomPropertiesContext.Provider value={this.lookup}>
                <div style={customProperties}>{children}</div>
            </CustomPropertiesContext.Provider>
        )
    }
}
