import * as React from "react"
import { FramerEventSession } from "../events/FramerEventSession"
import { FrameWithMotion } from "../render/presentation/Frame"
import { FramerEventListener, FramerEvent } from "../events"

export interface ScreenProperties {
    width: number
    height: number
    scale?: number
    color?: string
}

export class Screen extends React.Component<ScreenProperties> {
    session = new FramerEventSession(this.dispatcher)
    private elementRef = React.createRef<HTMLDivElement>()

    componentDidMount() {
        if (this.elementRef.current) {
            this.session.originElement = this.elementRef.current
        }
    }

    render() {
        const { width, height, scale, color = "none", children } = this.props

        const frame = (
            <FrameWithMotion
                ref={this.elementRef}
                width={width}
                height={height}
                style={{ originX: 0, originY: 0, scale, backgroundColor: color }}
            >
                {children}
            </FrameWithMotion>
        )

        return <FramerEventListener session={this.session}>{frame}</FramerEventListener>
    }

    private dispatcher(type: string, event: FramerEvent, target: EventTarget) {
        target.dispatchEvent(new CustomEvent("FramerEvent", { bubbles: true, detail: { type: type, event: event } }))
    }
}
