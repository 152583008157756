import FontFaceObserver from "fontfaceobserver"

const FONT_LOADING_TIMEOUT = 5000 // Amount of ms to wait when detecting if a font is ready
const MAX_RETRIES = 3 // Max number of times to retry font loading in case network error occurs

export class FontLoadingError extends Error {
    constructor(message: string) {
        super(message)
        this.name = "FontLoadingError"
    }
}

const fontRequests = new Map<string, Promise<void>>()
const fontReadyPromises = new Map<string, Promise<void>>()

interface FontFaceData {
    family: string
    url: string
    weight?: number
    style?: string
}

/** @internal  */
export const loadFont = (data: FontFaceData, doc: Document): Promise<void> => loadFontWithRetries(data, doc)

async function loadFontWithRetries(data: FontFaceData, doc: Document, attempt = 0): Promise<void> {
    const { family, url } = data
    const weight = data.weight || 500
    const style = data.style || "normal"

    const requestId = `${family}-${style}-${weight}-${url}`
    if (!fontRequests.has(requestId) || attempt > 0) {
        const fontFace = new FontFace(family, `url(${url})`, {
            weight: weight?.toString(),
            style,
        })
        const readyPromise = fontFace
            // Load the font
            .load()
            .then(() => {
                // Add the font to the document
                doc.fonts.add(fontFace)
                // Wait until it's fully ready
                return isFontReady(family, style, weight)
            })
            .catch(e => {
                if (e.name !== "NetworkError") {
                    throw e
                }
                // In case of a network error; retry
                if (attempt < MAX_RETRIES) {
                    return loadFontWithRetries(data, doc, attempt + 1)
                }
                // Throw error when retry limit has been reached
                throw new FontLoadingError(`Font loading failed after ${attempt} retries due to network error`)
            })
        fontRequests.set(requestId, readyPromise)
    }
    await fontRequests.get(requestId)
}

/** @internal  */
export async function isFontReady(
    family: string,
    style: string | undefined,
    weight: number | undefined
): Promise<void> {
    const readyPromiseId = `${family}-${style}-${weight}`
    if (!fontReadyPromises.has(readyPromiseId)) {
        const observer = new FontFaceObserver(family, {
            style,
            weight,
        })
        const readyPromise = observer.load(null, FONT_LOADING_TIMEOUT)
        fontReadyPromises.set(readyPromiseId, readyPromise)
    }
    try {
        await fontReadyPromises.get(readyPromiseId)
    } catch (e) {
        throw new FontLoadingError(`Failed to check if font is ready (${FONT_LOADING_TIMEOUT}ms timeout exceeded)`)
    }
}

/**
 * @internal
 * Util function for use in tests to clear state between test cases
 * */
export function _clearCache() {
    fontRequests.clear()
    fontReadyPromises.clear()
}
