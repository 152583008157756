import * as React from "react"
import { useLayoutId } from "./useLayoutId"

/**
 * Higher Order Component to provide a generated layoutId to the wrapped
 * component in the Framer preview, but preserving any user-specified layoutId
 * prop.
 *
 * @internal
 */
export const withGeneratedLayoutId = <T extends object>(Component: React.ComponentType<T>) =>
    React.forwardRef((props: T, ref) => {
        const layoutId = useLayoutId(props)

        return <Component layoutId={layoutId} {...props} layoutIdKey={undefined} duplicatedFrom={undefined} ref={ref} />
    })
