import * as React from "react"
import { LayoutTree } from "./SharedLayoutTree"

/**
 * @internal
 */
export interface LayoutTreeContextProps {
    promoteTree: (tree: LayoutTree, animates: boolean, transition: any, resets?: boolean) => boolean
    markTreeAsSafeToRemove: (tree: LayoutTree) => void
}
/**
 * @internal
 */
export const LayoutTreeContext = React.createContext<LayoutTreeContextProps>({
    promoteTree: () => false,
    markTreeAsSafeToRemove: () => {},
})
