import type { NativeScrollProps } from "./types"

import * as React from "react"
import { cx } from "../../modules/cx"
import { FrameWithMotion } from "../../render/presentation/Frame/FrameWithMotion"
import { isFiniteNumber } from "../../render/utils/isFiniteNumber"
import { useLayoutId } from "../../render/utils/useLayoutId"
import { useEmulateTouchScroll } from "../utils/useEmulatedTouchScroll"
import { isMotionValue } from "../../render/utils/isMotionValue"
import { injectComponentCSSRules } from "../../render/utils/injectComponentCSSRules"
import { useIsInCurrentNavigationTarget } from "../NavigationContainerContext"
import { useUpdateScrollOffset } from "../utils/useUpdateScrollOffset"
import { EmptyState } from "../EmptyState"

/**
 * @private
 */
export const NativeScroll = React.forwardRef<HTMLDivElement, NativeScrollProps>(function NativeScroll(
    props,
    forwardedRef: React.RefObject<HTMLDivElement> | null
) {
    const {
        direction = "vertical",
        scrollBarVisible = false,
        dragEnabled = true,
        contentOffsetX = 0,
        contentOffsetY = 0,
        contentWidth,
        contentHeight,
        children,
        resetOffset,
        onScroll,
        className,
        // Not (yet) supported
        directionLock = false,
        wheelEnabled = true,
        scrollAnimate,
        dragTransition,
        dragMomentum,
        dragElastic,
        overdragEnabled = true,
        onScrollStart,
        onScrollEnd,
        onDragStart,
        onDrag,
        onDragEnd,
        onUpdate,
        onDirectionLock,
        layoutId: specificLayoutId,
        native,
        // Rest
        ...containerProps
    } = props

    const layoutId = useLayoutId(props, { specificLayoutId, postfix: "scroll" })

    const fallbackRef = React.useRef<HTMLDivElement>(null)
    const ref = forwardedRef || fallbackRef

    const { cancelEmulatedTouchScrollAnimation } = useEmulateTouchScroll(ref, direction, dragEnabled)

    injectComponentCSSRules()

    const isInTarget = useIsInCurrentNavigationTarget()
    const previousIsInTargetRef = React.useRef(isInTarget)

    const updateScrollOffsetHandler = () => {
        if (!resetOffset) return
        const previousIsTarget = previousIsInTargetRef.current
        previousIsInTargetRef.current = isInTarget
        const shouldResetOffset = isInTarget && !previousIsTarget
        if (!shouldResetOffset) return
        const element = ref.current
        if (!element) return
        if (direction !== "vertical") {
            cancelEmulatedTouchScrollAnimation?.()
            element.scrollLeft = Math.abs(isMotionValue(contentOffsetX) ? contentOffsetX.get() : contentOffsetX)
        }
        if (direction !== "horizontal") {
            cancelEmulatedTouchScrollAnimation?.()
            element.scrollTop = Math.abs(isMotionValue(contentOffsetY) ? contentOffsetY.get() : contentOffsetY)
        }
    }

    // We only want to update the scroll offset when isInTarget changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useLayoutEffect(updateScrollOffsetHandler, [isInTarget])

    useUpdateScrollOffset(ref, "scrollLeft", contentOffsetX, cancelEmulatedTouchScrollAnimation)
    useUpdateScrollOffset(ref, "scrollTop", contentOffsetY, cancelEmulatedTouchScrollAnimation)

    const size = !containerProps.__fromCanvasComponent
        ? {
              width: containerProps.__fromCodeComponentNode ? "100%" : containerProps.width,
              height: containerProps.__fromCodeComponentNode ? "100%" : containerProps.height,
          }
        : {}

    return (
        <FrameWithMotion
            ref={ref}
            data-framer-component-type="NativeScroll"
            background="none" // need to set here to prevent default background from Frame
            {...containerProps}
            {...size}
            onScroll={onScroll}
            layoutId={layoutId}
            onBeforeLayoutMeasure={updateScrollOffsetHandler}
            className={cx(className, `direction-${direction}`, !scrollBarVisible && "scrollbar-hidden")}
        >
            <EmptyState
                children={children!}
                size={{
                    width: isFiniteNumber(containerProps.width) ? containerProps.width : "100%",
                    height: isFiniteNumber(containerProps.height) ? containerProps.height : "100%",
                }}
                insideUserCodeComponent={!containerProps.__fromCodeComponentNode}
                title="Scroll"
                description="Click and drag the connector to any frame on the canvas →"
            />
            {children}
        </FrameWithMotion>
    )
})
