import * as React from "react"
import { motion } from "framer-motion"
import { runtime } from "../../utils/runtimeInjection"
import { isBrowser } from "../../utils/environment"
import { safeWindow } from "../../utils/safeWindow"
import { imageRenderingForZoom, minZoomForPixelatedImageRendering } from "../utils/imageRendering"
import { BackgroundImage, ImageFit } from "../types/BackgroundImage"
import { RenderEnvironment, RenderTarget } from "../types/RenderEnvironment"
import { Size } from "../types/Size"

const wrapperStyle: React.CSSProperties = {
    position: "absolute",
    pointerEvents: "none",
    userSelect: "none",
    borderRadius: "inherit",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
}

function cssObjectFit(imageFit?: ImageFit) {
    switch (imageFit) {
        case "fit":
            return "contain"
        case "stretch":
            return "fill"
        default:
            return "cover"
    }
}

export function cssImageRendering(image: BackgroundImage, containerSize?: Size) {
    if (!containerSize) return "auto"

    // Set appropriate image rendering for the current environment
    const devicePixelRatio = RenderTarget.current() === RenderTarget.canvas ? safeWindow.devicePixelRatio : 1
    const minPixelatedZoom = minZoomForPixelatedImageRendering(image, containerSize, devicePixelRatio)

    if (RenderTarget.current() === RenderTarget.canvas) {
        // On the canvas we want to always keep image-rendering stable during zoom (hence the zoom = 1) the canvas pixelates the images on zooming in with a css rule
        return imageRenderingForZoom(1, minPixelatedZoom)
    } else {
        // In the preview or with export we might require a higer zoom level where images need to get pixelated if their frame is larger then their intrinsic size
        return imageRenderingForZoom(RenderEnvironment.zoom, minPixelatedZoom)
    }
}

function getImageStyle(image: BackgroundImage, containerSize?: Size): React.CSSProperties {
    return {
        pointerEvents: "none",
        userSelect: "none",
        display: "block",
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        objectPosition: "center",
        objectFit: cssObjectFit(image.fit),
        imageRendering: cssImageRendering(image, containerSize),
    }
}

interface Props {
    image: BackgroundImage
    containerSize?: Size
    nodeId?: string
    layoutId?: string
}

export function BackgroundImageComponent({ image, containerSize, nodeId, layoutId }: Props) {
    const wrapperRef = React.useRef<HTMLDivElement>(null)

    // These variables should never change for the lifetime of the component so it's fine to conditionally use hooks
    const isStaticRendering = !isBrowser() || RenderTarget.current() === RenderTarget.export

    if (layoutId) {
        layoutId = layoutId + "-background"
    }

    const source = runtime.useImageSource(image, containerSize, nodeId)
    const imageStyle = getImageStyle(image, containerSize)

    if (!isStaticRendering) {
        const imageElement = runtime.useImageElement(image, containerSize, nodeId)

        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useLayoutEffect(() => {
            const wrapper = wrapperRef.current

            if (wrapper === null) return
            wrapper.appendChild(imageElement)

            return () => {
                wrapper.removeChild(imageElement)
            }
        }, [imageElement])

        Object.assign(imageElement.style, imageStyle)
    }

    return (
        <motion.div ref={wrapperRef} style={wrapperStyle} layoutId={layoutId}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            {isStaticRendering ? <img src={source} style={imageStyle} /> : null}
        </motion.div>
    )
}
