export const correctBorderScale = (axis: "x" | "y") => ({
    process: (latest: number, { delta, treeScale }: any) => {
        if (typeof latest === "string") latest = parseFloat(latest)
        if (latest === 0) return "0px"

        let corrected = Math.round(latest / delta[axis].scale / treeScale[axis])
        corrected = Math.max(corrected, 1)
        return corrected + "px"
    },
})
